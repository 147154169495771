import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class ActivityService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('activity');
    }
    getAll(): Observable<any> {
        return this.http.get(`${this.path}`);
    }
    getByCompany(company: any): Observable<any> {
        return this.http.get(`${this.path}?action=select&company=${company}`);
    }
    getBySubdivision(subdivision: any): Observable<any> {
        return this.http.get(`${this.path}?action=subdivision&subdivision=${subdivision}`);
    }
    getBypaged(company: any, orderByExpression: any, pageIndex: any, pageSize: any): Observable<any> {
        if (!orderByExpression) { orderByExpression = '' }
        return this.http.get(`${this.path}?action=paged&company=${company}&orderByExpression=${orderByExpression}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    getByOverview(): Observable<any> {
        return this.http.get(`${this.path}?action=overview`);
    }
    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getRecall(pageIndex: any, pageSize: any): Observable<any> {
        return this.http.get(`${this.path}?action=recall&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    getByHouse(house: any): Observable<any> {
        return this.http.get(`${this.path}?action=house&house=${house}`);
    }
    getByHouseAddActivity(house: any): Observable<any> {
        return this.http.get(`${this.path}?action=houseaddactivity&house=${house}`);
    }
    create(model: any): Observable<any> {
        return this.http.post<any>(`${this.path}`, model);
    }
    update(id: any, model: any): Observable<any> {
        return this.http.put<any>(`${this.path}/${id}`, model);
    }
    bulkUpdate(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}?action=bulk`, model);
    }
    recallActivity(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/recall`, model);
    }
    getByContact(action: string, contact: number): Observable<any> {
        return this.http.get(`${this.path}?action=${action}&contact=${contact}`);
    }
    getRollCallByBuilder(subdivision: number): Observable<any> {
        return this.http.get(`${this.path}?action=rollcallbybuilder&subdivision=${subdivision}`)
    }
    getRollCallByBuilderByPaged(subdivision: any, pageIndex: any, pageSize: any): Observable<any> {
        return this.http.get(`${this.path}?action=rollcallbybuilderByPaged&subdivision=${subdivision}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    getByTemplateAddActivity(template: any): Observable<any> {
        return this.http.get(`${this.path}?action=templateaddactivity&template=${template}`);
    }
}