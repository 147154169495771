import { Directive, ElementRef, EventEmitter, HostListener, Input, NgModule, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appDropdownOpen], [appIgxDialogFullScreen], [appMatDialogFullScreen]'
})
export class DropdownOpenDirective {

  constructor(private el: ElementRef) { }
  @Input('isFullScreen') isFullScreen: boolean = false;
  @Output() outFullScreen = new EventEmitter<boolean>();

  defaultHeight: number = 0;
  defaultWidth: number = 0;

  @HostListener('opening')
  onDropDownOpening() {
    if ((this.el.nativeElement.attributes).hasOwnProperty('appdropdownopen')) {
      let elem: HTMLCollection = document.getElementsByClassName('igx-overlay__content') as HTMLCollection;
      let elem_1: HTMLElement;
      if (elem.length === 1) {
        elem_1 = elem[0] as HTMLElement;
      } else {
        elem_1 = elem[1] as HTMLElement;
      }
      // for click event (click)="onSubdivisionClick($event)"
      // elem_1.style.minWidth = event.currentTarget.offsetWidth + 'px'; 
      // for open event (opened)='onSubdivisionClick($event)'
      // elem_1.style.minWidth = event.owner.elementRef.nativeElement.offsetWidth + 'px'; 
      // for opening event (opening)='onSubdivisionClick($event)'
      // elem_1.style.minWidth = event.owner.elementRef.nativeElement.offsetWidth + 'px';
      elem_1.style.minWidth = this.el.nativeElement.offsetWidth + 'px';
    }
  }

  @HostListener('click')
  onResizeDialog() {
    if ((this.el.nativeElement.attributes).hasOwnProperty('appigxdialogfullscreen')) {
      let elem: HTMLCollection = document.getElementsByClassName('igx-overlay__content--modal') as HTMLCollection;
      let elem_1: HTMLElement;
      if (elem.length === 1) {
        elem_1 = elem[0] as HTMLElement;
      } else {
        elem_1 = elem[1] as HTMLElement;
      }

      elem_1.style.transform = '';

      let elem_innerChild: HTMLElement = elem_1.firstChild as HTMLElement;
      if (!this.isFullScreen) {
        this.defaultWidth = JSON.parse(JSON.stringify(elem_1.clientWidth));
        this.defaultHeight = JSON.parse(JSON.stringify(elem_1.clientHeight));

        elem_innerChild.style.width = '100%';
        elem_innerChild.style.height = '100%';

        let elem_innerChild_1: HTMLElement = elem_innerChild.firstChild as HTMLElement;
        elem_innerChild_1.style.width = '100%';
        elem_innerChild_1.style.height = '100%';

        elem_1.style.width = '100%';
        elem_1.style.height = '100%';
        elem_1.style.minHeight = '100%';

        this.isFullScreen = true;
      } else {
        elem_innerChild.style.width = this.defaultWidth.toString() + 'px';
        elem_innerChild.style.height = this.defaultHeight.toString() + 'px';

        let elem_innerChild_1: HTMLElement = elem_innerChild.firstChild as HTMLElement;
        elem_innerChild_1.style.width = this.defaultWidth.toString() + 'px';
        elem_innerChild_1.style.height = this.defaultHeight.toString() + 'px';

        elem_1.style.width = this.defaultWidth.toString() + 'px';
        elem_1.style.height = this.defaultHeight.toString() + 'px';
        elem_1.style.minHeight = this.defaultHeight.toString() + 'px';
        this.isFullScreen = false;
      }
      this.outFullScreen.emit(this.isFullScreen);
    } else if ((this.el.nativeElement.attributes).hasOwnProperty('appmatdialogfullscreen')) {
      const elements = document.querySelectorAll('.cdk-overlay-pane');
      let elem_1: HTMLElement;
      if (elements.length === 1) {
        elem_1 = elements[0] as HTMLElement;
      } else {
        elem_1 = elements[1] as HTMLElement;
      }
      elem_1.style.transform = '';

      let elem_innerChild: HTMLElement = elem_1.firstChild!.nextSibling as HTMLElement;
      if (!this.isFullScreen) {
        this.defaultWidth = JSON.parse(JSON.stringify(elem_1.clientWidth));
        this.defaultHeight = JSON.parse(JSON.stringify(elem_1.clientHeight));

        elem_innerChild.style.width = '100%';
        elem_innerChild.style.height = '100%';

        // let elem_innerChild_1: HTMLElement = elem_innerChild.firstChild as HTMLElement;
        // elem_innerChild_1.style.width = '100%';
        // elem_innerChild_1.style.height = '100%';

        elem_1.style.width = '100%';
        elem_1.style.height = '100%';
        elem_1.style.minHeight = '100%';

        this.isFullScreen = true;
      } else {
        // elem_innerChild.style.width = this.defaultWidth.toString() + 'px';
        // elem_innerChild.style.height = this.defaultHeight.toString() + 'px';

        // let elem_innerChild_1: HTMLElement = elem_innerChild.firstChild as HTMLElement;
        // elem_innerChild_1.style.width = this.defaultWidth.toString() + 'px';
        // elem_innerChild_1.style.height = this.defaultHeight.toString() + 'px';

        elem_1.style.width = this.defaultWidth.toString() + 'px';
        elem_1.style.height = this.defaultHeight.toString() + 'px';
        elem_1.style.minHeight = this.defaultHeight.toString() + 'px';
        this.isFullScreen = false;
      }
      this.outFullScreen.emit(this.isFullScreen);
    }
  }

  @HostListener('document:dialogClosed', ['$event'])
  resetVariable() {
    this.isFullScreen = false; // Reset the variable when the event is detected
  }

}

@NgModule({
  declarations: [DropdownOpenDirective],
  exports: [DropdownOpenDirective]
})

export class DropdownOpenDirectiveModule { }

