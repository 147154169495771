<!-- <div id="http-loader" *ngIf="(loadingIndicator$ | async) === true">
  <div id="pause" class="d-flex align-items-center justify-content-center">
    <div id="spinner"></div>
  </div>
</div> -->

<div class="admin-top-content-login"
  *ngIf="loginDisplay !== null && loginDisplay === false && !isRedirectPage && (loader.loading$ | async) === false">
  <div>
    <a href="/">
      <img class="png-icon" src='{{ "assets/icons/SAMLogo.png" }}'>
    </a>
  </div>
  <button mat-raised-button class="login-button" (click)="loginRedirect()">Login</button>
</div>
<!-- <button mat-raised-button class="login-button" *ngIf="loginDisplay" (click)="logout()">Logout</button> -->
<!-- <a class="login-button"
  *ngIf="loginDisplay !== null && loginDisplay === true && !isRedirectPage && (loader.loading$ | async) === false">
  <span [igxToggleAction]="menu" igxIconButton="flat" (click)="isShowDown()" (clickOutside)="onClickedOutside($event)" [overlaySettings]="overlaySettings">
    <igx-icon class="material-icons" style="color: black;">keyboard_arrow{{ isExpand ? '_up' : '_down' }}</igx-icon>
  </span>
</a>
<igx-drop-down #menu>
  <igx-drop-down-item>
    <div (click)="logout()">Logout</div>
  </igx-drop-down-item>
</igx-drop-down> -->

<app-toaster-container class="toaster-wrapper"></app-toaster-container>

<router-outlet *ngIf="loginDisplay !== null && loginDisplay === true && !isRedirectPage">
  <!-- <app-spinner></app-spinner> -->
  <loading></loading>
</router-outlet>