<igx-card-header class="dialog-header" style="overflow: auto;z-index: 999;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle [cdkDragDisabled]="outIsFullScreen">
    {{ cardHeader | titlecase }}
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="btn-close-icon col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<button igxButton="flat" class="btn-close-icon col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="add-vendor" style="height: calc(100% - 120px);">
    <form class="form-wrapper" id="dialog-house-on-hold" [formGroup]="commonForm">
        <igx-card>
            <igx-card-content>
                <table class="mt-2 column" style="width: 100%;height: 100%;">
                    <tr class="row">
                        <td class="col-sm-5 col-md-9 col-lg-8 col-xl-12">
                            <label igxLabel class="igx-label">{{lblName}}</label>
                            <div mat-dialog-content>
                                <igx-simple-combo [data]="customList" [displayKey]="'title'" [valueKey]="'value'"
                                    placeholder="Select" #cmbCustomList name="cmbCustomList"
                                    formControlName="cmbCustomList" appDropdownOpen>
                                </igx-simple-combo>
                                <mat-error *ngIf="hasError('cmbCustomList', 'required')">
                                    Required
                                </mat-error>
                            </div>
                        </td>
                    </tr>

                    <tr class="row">
                        <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                                <button igxButton="flat" igxRipple (click)="getData()" class="me-2"
                                    style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                                <button igxButton="flat" igxRipple (click)="close()"
                                    style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </igx-card-content>
        </igx-card>
    </form>
</div>