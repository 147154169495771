import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class JWTTokenService {

  public jwtToken: string = '';
  decodedToken: any = {};

  constructor(private localService: LocalService) {
  }

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
      this.localService.saveData('idToken', token);
    }
  }

  decodeToken() {
    // if (this.jwtToken && this.jwtToken !== '') {
    this.decodedToken = jwtDecode(this.jwtToken);
    // }
    // else if (await this.localService.getData('idToken')) {
    //   this.jwtToken = await this.localService.getData('idToken');
    //   this.decodedToken = jwtDecode(this.jwtToken);
    // }
  }

  getDecodeToken() {
    return jwtDecode(this.jwtToken);
  }

  getUser() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.name : null;
  }
  getUserId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.oid : null;
  }
  getGroup() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.groups : null;
  }
  getPreferedUserName() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.preferred_username : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    if (this.jwtToken && this.jwtToken !== '') {
      const expiryTime: number = this.getExpiryTime();
      if (expiryTime) {
        return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
      } else {
        return true;
      }
    }
    return true;
  }
}
