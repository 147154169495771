<igx-card-header class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle
    [cdkDragDisabled]="outIsFullScreen">
    {{isModify ? 'Update ': 'Add ' + cardHeader}}
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="add-appointment-meeting" (window:resize)="onResize($event)" style="height: calc(100% - 40px);overflow: auto;">
    <div style="height: calc(100%)">
        <form class="form-wrapper" id="dialog-appointment" #appointmentForm="ngForm" style="overflow: auto;">
            <div *ngIf="isPastDateWarning" style="background-color: #BCD8FB; padding: 5px; border: 1px solid;">
                <span> This {{cardHeader}} occurs in the past </span>
            </div>
            <igx-card>
                <igx-card-content>
                    <table class="column" style="width: 100%;">
                        <tr class="row">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label igxLabel class="igx-label">Type :</label>
                                <div mat-dialog-content>
                                    <igx-simple-combo [(ngModel)]="cardHeader" [data]="types" #eventType1="ngModel"
                                        name="eventType1" [displayKey]="'display'" [valueKey]="'display'"
                                        appDropdownOpen (selectionChanging)="onChangeEventType($event)">
                                    </igx-simple-combo>
                                </div>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label igxLabel class="igx-label asterisklabel">Subject :</label>
                                <div mat-dialog-content>
                                    <igx-input-group>
                                        <input igxInput InputBoxTrim class="igx-input" [(ngModel)]="appObj.app_subject"
                                            #subject="ngModel" name="subject" type="text" required />
                                    </igx-input-group>
                                    <div *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="alert">
                                        <div *ngIf="subject.errors?.['required']">Required </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label igxLabel class="igx-label">Location :</label>
                                <div mat-dialog-content>
                                    <igx-input-group>
                                        <input igxInput InputBoxTrim class="igx-input" [(ngModel)]="appObj.app_location"
                                            #location="ngModel" name="location" type="text" />
                                    </igx-input-group>
                                </div>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label igxLabel class="igx-label">Start time :</label>
                                <div mat-dialog-content>
                                    <igx-date-picker [(ngModel)]="appObj.app_startdatetime"
                                        [maxValue]="appObj.app_enddatetime" [mode]="'dialog'" name="startDate"
                                        #startDate="ngModel">
                                    </igx-date-picker>
                                    <div *ngIf="startDate.invalid && (startDate.dirty || startDate.touched)"
                                        class="alert">
                                        <div *ngIf="startDate.errors?.['required']">Required </div>
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-4 col-lg-4 col-xl-4 secInput">
                                <igx-time-picker #startTimePicker="ngModel" name="startTimePicker"
                                    [disabled]="appObj.app_alldayevent" [(ngModel)]="app_startTime"
                                    [ngModelOptions]="{standalone: true}" [mode]="mode" [spinLoop]='false'
                                    [inputFormat]="format" [itemsDelta]="{ hours: 1, minutes: 30 }">
                                </igx-time-picker>
                            </td>
                            <td class="col-sm-12 col-md-4 col-lg-4 col-xl-4 secInput">
                                <div mat-dialog-content>
                                    <igx-checkbox [(ngModel)]="appObj.app_alldayevent" #allDayEvent="ngModel"
                                        name="allDayEvent">All day event
                                    </igx-checkbox>
                                </div>
                            </td>
                        </tr>
                        <tr class="row">
                            <td class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <label igxLabel class="igx-label">End time :</label>
                                <div mat-dialog-content>
                                    <igx-date-picker [(ngModel)]="appObj.app_enddatetime"
                                        [minValue]="appObj.app_startdatetime" [mode]="'dialog'" name="endDate"
                                        #endDate="ngModel">
                                    </igx-date-picker>
                                    <div *ngIf="endDate.invalid && (endDate.dirty || endDate.touched)" class="alert">
                                        <div *ngIf="endDate.errors?.['required']">Required
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="col-sm-12 col-md-4 col-lg-4 col-xl-4 secInput">
                                <igx-time-picker #endTimePicker="ngModel" name="endTimePicker" [(ngModel)]="app_endTime"
                                    [ngModelOptions]="{standalone: true}" [mode]="mode" [spinLoop]='false'
                                    [disabled]="appObj.app_alldayevent" [inputFormat]="format"
                                    [itemsDelta]="{ hours: 1, minutes: 30 }" [type]="'line'">
                                </igx-time-picker>
                            </td>
                            <td class="col-sm-12 col-md-4 col-lg-4 col-xl-4 secInput">
                                <div mat-dialog-content>
                                    <igx-checkbox [(ngModel)]="appObj.app_remider" #reminder="ngModel"
                                        name="reminder">Reminder
                                    </igx-checkbox>
                                </div>
                            </td>
                        </tr>
                        <tr class="row mt-2">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div mat-dialog-content>
                                    <igx-input-group>
                                        <textarea igxInput InputBoxTrim cols="30" rows="5" [(ngModel)]="appObj.app_description"
                                            #description="ngModel" name="description"></textarea>
                                    </igx-input-group>
                                </div>
                            </td>
                        </tr>
                        <!-- <tr class="row">
                            <td class="col-sm-1 col-md-3 col-lg-2 col-xl-2"> </td>
                            <td class="col-sm-11 col-md-4 col-lg-10 col-xl-10">
                                <div mat-dialog-actions class="d-flex justify-content-end button-sample mt-2">
                                    <button igxButton="flat" igxRipple (click)="save()" class="me-2"
                                        style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                                    <button igxButton="flat" igxRipple (click)="closeDialog()"
                                        style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                                </div>
                            </td>
                        </tr> -->
                    </table>
                </igx-card-content>
            </igx-card>
        </form>
        <div igxDialogActions>
            <button igxButton="flat" igxRipple (click)="save()" class="me-2"
                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
            <button igxButton="flat" igxRipple (click)="closeDialog()"
                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
        </div>
    </div>
</div>