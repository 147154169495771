import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends ApiService<any> {

  constructor(protected http: HttpClient) {
    super('review');
  }
  getBlog(contact: number): Observable<any> {
    return this.http.get(`${this.path}?action=blog&contact=${contact}&childcat=ATS`);
  }
  selectBlog(child: number, childcat: string, isWarranty: any = false): Observable<any> {
    return this.http.get(`${this.path}?action=select&child=${child}&childcat=${childcat}&isWarranty=${isWarranty}`);
  }
  create(model: any, isWarranty: any = false) {
    return this.http.post<any>(`${this.path}?isWarranty=${isWarranty}`, model);
  }
  getHouseOwnerComments(child: number, childcat: string): Observable<any> {
    return this.http.get(`${this.path}?action=byhouseportal&child=${child}&childcat=${childcat}`)
  }
}
