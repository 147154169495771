import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailCommunicationService extends ApiService<any> {
  constructor(protected http: HttpClient) {
    super('email-communication')
  }

  sendEmail(model: any): Observable<any> {
    return this.http.put<any>(`${this.path}`, model);
  }
  sendSMS(model: any): Observable<any> {
    return this.http.put<any>(`${this.path}/sendsms`, model);
  }
  sendAppointment(model: any): Observable<any> {
    return this.http.put<any>(`${this.path}/sendAppointment`, model);
  }
}
