import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { GradientConfig } from 'src/app/app-config';
import { NavigationItem, NavigationItems } from './navigation/navigation';
import { ConnectedPositioningStrategy, HorizontalAlignment, IgxExpansionPanelComponent, IgxNavigationDrawerComponent, IgxTreeComponent, NoOpScrollStrategy, VerticalAlignment } from 'igniteui-angular';
import { Globals } from 'src/globals';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { IColumnPipeArgs, IgxDialogComponent } from 'igniteui-angular';
import { MatDialog } from '@angular/material/dialog';
import { TransferHousesComponent } from 'src/app/pages/dialogs/transfer-houses/transfer-houses.component';
import { LocalService } from 'src/app/core/services/local.service';
import { CommonService } from 'src/app/core/services/common.service';
import { LoadingService } from 'src/app/core/services/loader.service';
import { ReportsService } from 'src/app/core/services/reports.service';
import { timeStamp } from 'console';
import { ActivatedRouteSnapshot, NavigationStart, Route, Router } from '@angular/router';
import { ViewerComponent } from '@mescius/activereportsjs-angular';
import { el } from '@fullcalendar/core/internal-common';
import { PolicyService } from 'src/app/core/services/policy.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  // public props
  navCollapsed: boolean;
  navCollapsedMob: boolean;
  windowWidth: number;
  navigations: NavigationItem[];
  public selected = 'House';
  @ViewChild(IgxNavigationDrawerComponent, { static: true })
  public drawer!: IgxNavigationDrawerComponent;
  isCollapse: boolean = false;
  @ViewChild('transferHouse', { read: IgxDialogComponent, static: true })
  public transferHouse!: IgxDialogComponent;
  public contextmenu = false;
  @ViewChild(IgxExpansionPanelComponent, { read: IgxExpansionPanelComponent, static: true })
  public panel!: IgxExpansionPanelComponent;
  @ViewChild('tree1', { static: true }) public tree1!: IgxTreeComponent;
  @ViewChild(ViewerComponent, { static: false }) reportViewer!: ViewerComponent;
  loggedInUser: any = '';
  isAdministrator: boolean = false;
  isAssistanceBuilder: boolean = false;
  isBuilder: boolean = false;
  isManagement: boolean = false;
  isEstimator: boolean = false;
  isSales: boolean = false;
  isDesigner: boolean = false;
  isWarrantyManager: boolean = false;

  isHouseHasRole: boolean = false;
  isVendorHasRole: boolean = false;
  isSubdivisionHasRole: boolean = false;
  isSystemManagementFullHasRole: boolean = false;
  isSystemManagementHasRole: boolean = false;
  isTemplateHasRole: boolean = false;
  isWarrantyLettersHasRole: boolean = false;
  isGanttChartHasRole: boolean = false;
  isRecallHasRole: boolean = false;
  isPayrollHasRole: boolean = false;
  isAllListHasRole: boolean = false;
  isSAMScoreHasRole: boolean = false;
  isOverviewReportHasRole: boolean = false;
  isHouseTransferReportHasRole: boolean = false;
  isSAMScoreProblemHasRole: boolean = false;
  isTemplateModificationHasRole: boolean = false;

  isExpand: boolean = false;
  public user: any = [];
  // constructor
  public houseSAMScore = {
    isHouse: false,
    target: 0,
    cycleTime: 0,
    color: ''
  };
  isScore: boolean = false;
  score: number = 0
  isViewerHidden: any = false;
  constructor(
    private location: Location,
    private authenticationService: AuthenticationService,
    private policyService: PolicyService,
    private localStorage: LocalService,
    public globals: Globals,
    private authService: MsalService,
    private locationStrategy: LocationStrategy,
    private dialog: MatDialog,
    private commonService: CommonService,
    private loaderService: LoadingService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private reportService: ReportsService) {
    if (this.globals.loggedInuser$.value) {
      this.loggedInUser = this.globals.loggedInuser$.value.name;
    }
    else {
      this.checkAndSetActiveAccount();
    }
    this.navigations = JSON.parse(JSON.stringify(NavigationItems));
    this.authenticationService.allRole().then(value => {
      let promiseUser = this.authenticationService.getUserInfo();
      promiseUser.then((user) => {
        // this.loaderService.show();
        this.user = user;
        this.isAdministrator = value.Administrator;
        this.isAssistanceBuilder = value['Assistant Builder'];
        this.isBuilder = value.Builder;
        this.isManagement = value.Management;
        this.isEstimator = value.Estimator;
        this.isSales = value.Sales;
        this.isDesigner = value.Designer;
        this.isWarrantyManager = value["Warranty Manager"];

        this.getSAMScore();
        this.isViewerHidden = false;
        if ((this.isAssistanceBuilder || this.isBuilder) && this.router.routerState.snapshot.url === '/') {
          this.isViewerHidden = true;
          this.getDailyActivityReport();
        } else {
          // this.loaderService.hide(1);
        }
        this.setNavigationMenu();
      });
    }, error => {
      // this.loaderService.hide(1);
    });

    // this.navigations = JSON.parse(JSON.stringify(NavigationItems));
    // this.setNavigationMenu();
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }

    this.windowWidth = window.innerWidth;
    if (current_url === baseHref + '/layout/collapse-menu' || (this.windowWidth >= 992 && this.windowWidth <= 1024)) {
      // Sumit changes
      GradientConfig.collapse_menu = true;
    }

    this.navCollapsed = false;
    this.navCollapsedMob = true;
  }
  ngOnInit(): void {
    this.commonService.data$
      .subscribe(res => {
        if (res && res !== null) {
          this.houseSAMScore = res;
        }
      });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isViewerHidden = false;
        if ((this.isAssistanceBuilder || this.isBuilder) && event.url === '/') {
          this.isViewerHidden = true;
          this.getDailyActivityReport();
        }
      }
    });
    // this.authenticationService.allRole().then(value => {
    //   let promiseUser = this.authenticationService.getUserInfo();
    //   promiseUser.then((user) => {
    //     // this.loaderService.show();
    //     this.user = user;
    //     this.isAdministrator = value.Administrator;
    //     this.isAssistanceBuilder = value['Assistant Builder'];
    //     this.isBuilder = value.Builder;
    //     this.isManagement = value.Management;
    //     this.isEstimator = value.Estimator;
    //     this.isSales = value.Sales;
    //     this.isDesigner = value.Designer;
    //     this.isWarrantyManager = value["Warranty Manager"];

    //     this.getSAMScore();
    //     this.isViewerHidden = false;
    //     if ((this.isAssistanceBuilder || this.isBuilder) && this.router.routerState.snapshot.url === '/') {
    //       this.isViewerHidden = true;
    //       this.getDailyActivityReport();
    //     } else {
    //       // this.loaderService.hide(1);
    //     }
    //   });
    // }, error => {
    //   // this.loaderService.hide(1);
    // });
  }
  logout(popup?: boolean) {
    this.authenticationService.signOut(popup);
    this.localStorage.removeData('localUser');
    this.localStorage.removeData('company');
    this.localStorage.removeData('idToken');
    this.localStorage.removeData('role');
    this.localStorage.removeData('policy');
  }
  isShowDown() {
    if (this.isExpand) {
      this.isExpand = false;
    }
    else {
      this.isExpand = true;
    }
  }
  onClickedOutside(e: Event) {
    this.isExpand = false;
  }
  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Left,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Bottom,
    }),
    scrollStrategy: new NoOpScrollStrategy(),
  };
  getSAMScore() {
    if (this.isAdministrator || this.isManagement) {
      this.reportService.getPerformanceRankingsBySupervisorReport().subscribe(result => {
        let performanceRanking = result.filter((x: any) => x.cat_name === 'Overall Score');
        let contactIndex = performanceRanking.findIndex((x: any) => x.con_name === this.user.usr_name)
        if (contactIndex > -1) {
          this.isScore = true;
          this.score = (performanceRanking[contactIndex].score) * 100;
        }
      }, error => {
        // this.loaderService.hide(1);
      });
    } else {
      this.reportService.getPerformanceRankingsByBuilderReport().subscribe(result => {
        let performanceRanking = result.filter((x: any) => x.cat_name === 'Overall Score');
        let contactIndex = performanceRanking.findIndex((x: any) => x.con_name === this.user.usr_name)
        if (contactIndex > -1) {
          this.isScore = true;
          this.score = (performanceRanking[contactIndex].score) * 100;
        }
      }, error => {
        // this.loaderService.hide(1);
      });
    }
  }
  async getDailyActivityReport() {
    this.loaderService.show();
    this.reportService.getDailyActivityByBuilderReport().subscribe(async res => {
      const reportResponse = await fetch(`assets/reports/DailyActivityReport.rdlx-json`);
      const report = await reportResponse.json();
      report.DataSources[0].ConnectionProperties.ConnectString =
        "jsondata=" + JSON.stringify(res);

      report.DataSets[0].fields = res;
      report.DataSets[0].Query.CommandText = 'jpath=$.*';

      this.reportViewer.open(report);
      this.loaderService.hide();
    }, error => {
      this.loaderService.hide();
    });
  }
  async setNavigationMenu() {
    // const allRoles: any[] = await this.authenticationService.allRole();
    let policy = await this.localStorage.getData('policy');
    if (policy == null || policy === '') {
      let promisePolicyList = await this.getPolicy();
      Promise.all([promisePolicyList]).then((policyList) => {
      });
    }
    if (this.isAdministrator || this.isManagement || this.isAssistanceBuilder) {
      this.isHouseHasRole = true;
      this.isVendorHasRole = true;
      this.isSystemManagementHasRole = true;
      this.isSystemManagementFullHasRole = true;
      this.isSubdivisionHasRole = true;
      this.isTemplateHasRole = true;
      this.isWarrantyLettersHasRole = true;

      this.isGanttChartHasRole = true;
      this.isRecallHasRole = true;
      this.isSAMScoreHasRole = true;
      this.isPayrollHasRole = true;
      this.isAllListHasRole = true;
      this.isOverviewReportHasRole = true;
      this.isHouseTransferReportHasRole = true;
      this.isSAMScoreProblemHasRole = false;
      this.isTemplateModificationHasRole = false;
    }
    if (this.isBuilder) {
      this.isHouseHasRole = true;
      this.isVendorHasRole = true;
      this.isPayrollHasRole = true;
      this.isAllListHasRole = true;
      this.isGanttChartHasRole = true;
      this.isSystemManagementHasRole = true;
      this.isSubdivisionHasRole = true;
      this.isTemplateHasRole = true;
      this.isSAMScoreHasRole = true;
      this.isOverviewReportHasRole = true;
    }
    if (this.isEstimator) {
      this.isSystemManagementHasRole = true;
      this.isSubdivisionHasRole = true;
      this.isHouseHasRole = true;
      this.isOverviewReportHasRole = true;
    }
    if (this.isDesigner) {
      this.isHouseHasRole = true;
      this.isOverviewReportHasRole = true;
    }
    if (this.isSales) {
      this.isHouseHasRole = true;
      this.isSystemManagementHasRole = true;
      this.isSubdivisionHasRole = true;
    }
    if (this.isWarrantyManager) {
      this.isHouseHasRole = true;
      this.isWarrantyLettersHasRole = true;
      this.isVendorHasRole = true;
      this.isOverviewReportHasRole = true;
    }
    NavigationItems.filter(async (nav: any) => {
      let isAllow: any = true;
      if (
        (!this.isHouseHasRole && nav.policy == '060') ||
        (!this.isVendorHasRole && nav.policy == '050') ||
        (!this.isSubdivisionHasRole && nav.id == 'systemManagement') ||
        (!this.isWarrantyLettersHasRole && nav.policy == '129') ||
        (!this.isGanttChartHasRole && nav.id == 'gantt-chart') ||
        (!this.isPayrollHasRole && nav.id == 'allLists') ||
        (!this.isAllListHasRole && nav.id == 'payroll')
      ) {
        isAllow = false;
        this.navigations = this.navigations.filter(x => x.id !== nav.id);
      }
      else {
        if (nav.children) {
          let navChildren: any[] = this.navigations.filter((x: any) => x.id === nav.id);
          let childAllow: any = true;
          nav.children.filter(async (childNav: any) => {
            if (!this.isSystemManagementFullHasRole && nav.id == 'systemManagement') {
              if (!this.isSubdivisionHasRole && childNav.policy == '030') {
                childAllow = false;
                navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
              }
              else if (!this.isTemplateHasRole && childNav.policy == '080') {
                childAllow = false;
                navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
              }
              else if (childNav.policy != '030' && childNav.policy != '080') {
                childAllow = false;
                navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
              }
            }

            else if (!this.isRecallHasRole && childNav.id == 'recall') {
              childAllow = false;
              navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
            }
            else if (!this.isOverviewReportHasRole && childNav.id == 'overview-schedule') {
              childAllow = false;
              navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
            }
            else if (!this.isHouseTransferReportHasRole && childNav.id == 'transfer-houses') {
              childAllow = false;
              navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
            }
            else if (!this.isSAMScoreProblemHasRole && childNav.id == 'sam_score_problem') {
              childAllow = false;
              navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
            }
            else if (!this.isTemplateModificationHasRole && childNav.id == 'template_modification') {
              childAllow = false;
              navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
            }
            else {
              let hasPermission = await this.authenticationService.hasPermission(childNav.policy);
              if (childNav.policy && !hasPermission) {
                childAllow = false;
                if (navChildren && navChildren.length > 0 && navChildren[0].children) {
                  navChildren[0].children = navChildren[0].children?.filter((x: any) => x.id !== childNav.id);
                }
              }
            }
            return childAllow;
          });
          if (!nav.children || nav.children.length < 1) {
            isAllow = false;
            this.navigations = this.navigations.filter(x => x.id !== nav.id);
          }
        }
        else if (nav.policy && !await this.authenticationService.hasPermission(nav.policy)) {
          isAllow = false;
          this.navigations = this.navigations.filter(x => x.id !== nav.id);
        }
      }
      return isAllow;
    });
  }
  async getPolicy(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.policyService.getByUser().subscribe(res => {
        res = res.map((x: any) => {
          return {
            PolicyArea: x.gat_id,
            AccessTypeId: x.pol_access
          }
        });
        this.localStorage.saveData('policy', JSON.stringify(res));
        resolve(res);
      });
    });
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    this.globals.loggedInuser$.next(activeAccount);
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.globals.loggedInuser$.next(accounts);
      this.authService.instance.setActiveAccount(accounts[0]);
    }
    this.loggedInUser = this.globals.loggedInuser$.value.name;
  }
  // public method
  navMobClick() {
    if (this.windowWidth < 992) {
      if (this.navCollapsedMob && !document.querySelector('app-navigation.pcoded-navbar')?.classList.contains('mob-open')) {
        // Sumit changes
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          // Sumit changes
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        // Sumit changes
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }
  public navigate(item: any) {
    this.selected = item.title;
    if (document.getElementsByTagName('igx-nav-drawer')[0].scrollWidth === 68 && item.type === 'collapse') {
      let collapsibleMenus: any = this.navigations.filter(res => res.type === 'collapse');
      if (collapsibleMenus && collapsibleMenus.length > 0) {
        collapsibleMenus.forEach((node: any) => {
          node.isExpanded = false;
        });
      }
      this.drawer.open();
      item.isExpanded = true;
      this.changeDetector.detectChanges();
    }
    else {
      // this.drawer.close();
    }
  }
  openPopup(item: any) {
    // if (item.url === 'tranferhouse') {
    if (item.id === 'transfer-houses') {
      this.contextmenu = false;
      const dialogRef = this.dialog.open(TransferHousesComponent, {
        disableClose: true,
      });
    }
  }
  logOut(): any {
    this.authenticationService.signOut();
    this.localStorage.removeData('localUser');
    this.localStorage.removeData('idToken');
  }
  changeDrawerToggle() {
    console.log('Before toggle : ', this.drawer.isOpen);
    if (this.drawer.isOpen) {
      this.drawer.close();
    }
    else {
      this.drawer.open();
    }
    console.log('After toggle : ', this.drawer.isOpen);
    // this.changeDetector.detectChanges();
  }
  mainMenuExpand(isExpand: any = false): any {
    let collapsibleMenus: any = this.navigations.filter(res => res.type === 'collapse');
    if (collapsibleMenus && collapsibleMenus.length > 0) {
      collapsibleMenus.forEach((node: any) => {
        node.isExpanded = false;
      });
    }
    if (isExpand) {
      let sysMenu: any = this.navigations.filter(res => res.id === 'systemManagement');
      if (sysMenu && sysMenu.length > 0) {
        sysMenu[0].isExpanded = true;
      }
    }
    this.changeDetector.detectChanges();
    return true;
  }
}
