import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
    providedIn: 'root'
})
export class PoRequestService extends ApiService<any> {
    constructor(protected http: HttpClient) {
        super('po-request');
    }

    get(pk: any): Observable<any> {
        return this.http.get(`${this.path}/${pk}`);
    }
    getByHouse(action: any, pk: any, isWarranty: any = false): Observable<any> {
        return this.http.get(`${this.path}?action=${action}&house=${pk}&isWarranty=${isWarranty}`);
    }
    getRecall(pageIndex: any, pageSize: any): Observable<any> {
        return this.http.get(`${this.path}?action=recall&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    recallPORequest(model: any[]): Observable<any> {
        return this.http.put<any>(`${this.path}/recall`, model);
    }
    update(pk: any, model: any, isWarranty: any = false): Observable<any> {
        return this.http.put<any>(`${this.path}/${pk}?isWarranty=${isWarranty}`, model);
    }
    create(model: any, isWarranty: any = false): Observable<any> {
        return this.http.post<any>(`${this.path}?isWarranty=${isWarranty}`, model);
    }
    getBySuperAdmin(): Observable<any> {
        return this.http.get(`${this.path}?action=superadmin`);
    }
    multiUpdate(models: any[], isWarranty: any = false) {
        return this.http.put<any>(`${this.path}?isWarranty=${isWarranty}`, models);
    }
    bulkUpdate(model: any) {
        return this.http.put<any>(`${this.path}/UpdateTaskPO`, model);
    }
    delete(pks: any, isWarranty: any = false): Observable<any> {
        let body: any = { body: pks }
        return this.http.delete<any>(`${this.path}?isWarranty=${isWarranty}`, body);
    }
    getPOOrderByHouse(house: any) {
        return this.http.get(`${this.path}?action=poorders&house=${house}`);
    }
    getTaskPORequestPendingByUser(action: string, pageIndex: number, pageSize: number) {
        return this.http.get(`${this.path}?action=${action}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    getTaskPORequestSubmittedByUser(action: string, pageIndex: number, pageSize: number, fromDate: any, toDate: any) {
        return this.http.get(`${this.path}?action=${action}&pageIndex=${pageIndex}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}`);
    }
    getTaskPORequestManagementByUser(action: string, pageIndex: number, pageSize: number) {
        return this.http.get(`${this.path}?action=${action}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
    getTaskPORequestRejectedByUser(action: string, pageIndex: number, pageSize: number) {
        return this.http.get(`${this.path}?action=${action}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
    }
}