<igx-card-header class="dialog-header" style="z-index: 999;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle [cdkDragDisabled]="outIsFullScreen">
    {{title}}
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="section-report-viewer" (resize)="onResize($event)" style="height: calc(100% - 37px);overflow: auto;">
    <div style="box-shadow:none;" style="height:100%">
        <div id="viewer-host">
            <gc-activereports-viewer [fullscreen]="false">
                <!-- [availableExports]="availableExports"> -->
            </gc-activereports-viewer>
        </div>
    </div>
</div>