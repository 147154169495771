import { environment } from "src/environments/environment";

export abstract class BackendService {
  private readonly basePath: string;
  protected readonly path: string;

  protected constructor(endpoint: string) {
    this.basePath = environment.isLocal ? environment.apiUri : `${window.location.origin}/api/`;
    this.path = `${this.basePath}${endpoint}`;
  }
}
