<igx-card-header class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle
    [cdkDragDisabled]="outIsFullScreen">
    PO Request History - {{ poNumber }}
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="add-edit-subdivision" (window:resize)="onResize($event)" style="height: calc(100% - 50px);">
    <!-- <igx-card style="height: calc(100%);"> -->
    <igx-card-content class="gradiant" style="height: calc(100%)">
        <div class="mat-elevation-z8 activtiy-table-wrapper" style="height: calc(100%);box-shadow:none;">
            <div class="grid__wrapper h-100">
                <div class="grid__wrapper__inner h-100">
                    <igx-grid #gridHistory [igxPreventDocumentScroll]="false" [data]="gridHistoryData" width="100%"
                        [primaryKey]="'poh_pk'" [autoGenerate]="false" [rowSelection]="'single'"
                        [cellSelection]="'none'" hiddenColumnsText="Hidden" [emptyGridTemplate]="myTemplate"
                        [hideRowSelectors]="true">

                        <ng-template #myTemplate>
                            <div style="margin-top: 50px; text-align: center;">
                                <label style="color: black;">No data found</label>
                            </div>
                        </ng-template>

                        <igx-column field="poh_rejectdate" header="Reject Date" [dataType]="'date'" [sortable]="false">
                            <ng-template igxCell let-cell="cell" let-val>
                                <span>{{ val | date: 'MM/dd/yyyy' }}</span>
                            </ng-template>
                        </igx-column>
                        <igx-column field="poh_rejectreason" header="Reject Reason" [dataType]="'string'"
                            [sortable]="false">
                            <ng-template igxCell let-cell="cell">
                                <span>{{cell.value}}</span>
                            </ng-template>
                        </igx-column>
                        <igx-column field="poh_rejectby" header="Reject By" [dataType]="'string'" [sortable]="false">
                            <ng-template igxCell let-cell="cell">
                                <span>{{cell.value}}</span>
                            </ng-template>
                        </igx-column>
                    </igx-grid>
                </div>
            </div>
        </div>
    </igx-card-content>
    <!-- </igx-card> -->
</div>