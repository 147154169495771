import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-common-dropdown',
  templateUrl: './common-dropdown.component.html',
  styleUrls: ['./common-dropdown.component.scss']
})
export class CommonDropdownComponent {
  customList: any[] = [];
  lblName: string = '';
  cardHeader: string = '';
  public commonForm: FormGroup;
  public outIsFullScreen = false;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CommonDropdownComponent>) {
    this.customList = data.customList;
    this.lblName = data.labelName;
    this.cardHeader = data.cardHeader;
    this.commonForm = new FormGroup({
      cmbCustomList: new FormControl(0, [Validators.required])
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.commonForm.controls[controlName].hasError(errorName);
  }
  getData(): void {
    if (this.commonForm.valid) {
      const _company = this.customList.find(x => x.value == this.commonForm.controls["cmbCustomList"].value);
      this.dialogRef.close({ ..._company });
    }
  }

  close(): void {
    this.dialogRef.close();
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  isDialogDrag(_fullScreen: boolean) {
    this.outIsFullScreen = _fullScreen;
  }
}
