<igx-card-header class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle
    [cdkDragDisabled]="outIsFullScreen">
    Blog - {{ contact.con_name }} - {{ contact.con_contact }}
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="btn-close-icon col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div (resize)="onResize($event)" class="pt-3" style="height: calc(100% - 40px);overflow: auto;">
    <div style="height: calc(100%)">
        <form class="p-2 form-wrapper" style="overflow: auto;">
            <igx-card>
                <igx-card-header class="igx-card-header">Comment</igx-card-header>
                <igx-card-content class="gradiant">
                    <form [formGroup]="commentForm">
                        <table class="mt-2">
                            <tr class="row">
                                <td class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <igx-input-group>
                                        <textarea igxInput InputBoxTrim [(ngModel)]="newReview.rev_note" name="comment" rows="2"
                                            #comment name="comment" formControlName="comment"></textarea>
                                    </igx-input-group>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                    <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                                        <igx-checkbox [checked]="true">
                                            Read
                                        </igx-checkbox>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button igxButton="flat" igxRipple class="me-2"
                                            style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));"
                                            (click)="close()">Close</button>
                                        <button igxButton="flat" igxRipple
                                            style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);"
                                            [disabled]="!commentForm.valid" (click)="postComment()">Post</button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </form>
                </igx-card-content>
            </igx-card>
        </form>

        <div class=" mat-elevation-z8 activtiy-table-wrapper" style="box-shadow:none;">
            <div class="p-2 grid__wrapper">
                <div class="grid__wrapper__inner"
                    [ngClass]="gridHOComments.nativeElement.clientWidth < 1024 ? 'mobile-view':'desktop-view'">
                    <igx-grid #gridHOComments [igxPreventDocumentScroll]="false" [data]="gridHouseOwnerCommentsData"
                        width="100%" [primaryKey]="'rev_pk'" [autoGenerate]="false" [rowSelection]="'single'"
                        [cellSelection]="'none'" [rowEditable]="false" hiddenColumnsText="Hidden"
                        [hideRowSelectors]="true" [emptyGridTemplate]="myTemplate">

                        <ng-template #myTemplate>
                            <div style="margin: 5%; text-align: center;">
                                <label style="color: black;">No data found</label>
                            </div>
                        </ng-template>

                        <igx-column-layout>
                            <igx-column field="rev_note" header="Comments" [dataType]="'string'" [sortable]="true"
                                [resizable]="true" [rowStart]="1" [colStart]="1">
                                <ng-template igxCell let-cell="cell">
                                    <span class="inner-res-label">{{cell.column.header}} : </span>
                                    <span>{{cell.value}}</span>
                                </ng-template>
                            </igx-column>
                            <igx-column field="rev_createdby" header="User" [dataType]="'string'" [sortable]="true"
                                [width]="'300px'" [rowStart]="gridHOComments.nativeElement.clientWidth < 1024 ? 2 : 1"
                                [colStart]="gridHOComments.nativeElement.clientWidth < 1024 ? 1 : 2">
                                <ng-template igxCell let-cell="cell">
                                    <span class="inner-res-label">{{cell.column.header}} : </span>
                                    <span>{{cell.value}}</span>
                                </ng-template>
                            </igx-column>
                            <igx-column field="rev_createddate" header="Date" [sortable]="true" [dataType]="'date'"
                                [pipeArgs]="pipeArgs" [width]="'150px'"
                                [rowStart]="gridHOComments.nativeElement.clientWidth < 1024 ? 3 : 1"
                                [colStart]="gridHOComments.nativeElement.clientWidth < 1024 ? 1 : 3">
                                <ng-template igxCell let-cell="cell">
                                    <span class="inner-res-label">{{cell.column.header}} : </span>
                                    <span>{{cell.value | date: 'MM/dd/yyyy'}}</span>
                                </ng-template>
                            </igx-column>
                        </igx-column-layout>
                    </igx-grid>
                </div>
            </div>
        </div>
    </div>
</div>