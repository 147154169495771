<igx-card-header class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle
    style="z-index: 999;" [cdkDragDisabled]="outIsFullScreen">
    Transfer House(s)
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="close()"
    style="    --component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="add-vendor" style="height: calc(100% - 120px);">
    <form class="form-wrapper" id="transfer-houses-dialog" [formGroup]="transferHouseForm">
        <igx-card>
            <igx-card-content>
                <table class="mt-2 column" style="width: 100%;height: 100%;">
                    <tr class="row">
                        <td class="col-sm-8 col-md-8 col-lg-8 col-xl-12">
                            <label igxLabel class="igx-label">Transfer From:</label>
                            <div mat-dialog-content>
                                <igx-simple-combo [data]="builderListFrom" [displayKey]="'usr_name'"
                                    [valueKey]="'usr_pk'" placeholder="Select Builder" #builderFrom name="builderFrom"
                                    (selectionChanging)="changeSelection($event)" formControlName="builderFrom"
                                    id="builderFrom" appDropdownOpen>
                                </igx-simple-combo>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-8 col-md-8 col-lg-8 col-xl-12">
                            <label igxLabel class="igx-label">Company:</label>
                            <div mat-dialog-content>
                                <igx-simple-combo [data]="companyList" [displayKey]="'com_name'" [valueKey]="'com_pk'"
                                    placeholder="Select Company" #companyName name="companyName"
                                    (selectionChanging)="changeSelection($event)" formControlName="companyName"
                                    id="companyName" appDropdownOpen>
                                </igx-simple-combo>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-8 col-md-8 col-lg-8 col-xl-12">
                            <label igxLabel class="igx-label">House:</label>
                            <div mat-dialog-content>
                                <igx-combo class="combo" [(ngModel)]="selectedHouse" [itemsMaxHeight]="250"
                                    name="houseName" #houseName [data]="houseList" [displayKey]="'hou_address'"
                                    [valueKey]="'hou_pk'" placeholder="Select House" searchPlaceholder="Search..."
                                    formControlName="houseName" (closing)="handleClosing($event)">
                                    <ng-template igxComboHeader>
                                        <div class=" select-all">
                                            <igx-checkbox class="igx-drop-down__item" [readonly]="true"
                                                [checked]="allSelected" (click)="handleSelect($event)">
                                                {{allSelected ? 'Dese' : 'Se'}}lect all</igx-checkbox>
                                        </div>
                                    </ng-template>
                                </igx-combo>
                            </div>
                        </td>
                    </tr>
                    <tr class="row">
                        <td class="col-sm-8 col-md-8 col-lg-8 col-xl-12">
                            <label igxLabel class="igx-label">Transfer To:</label>
                            <div mat-dialog-content>
                                <igx-simple-combo [data]="builderListTo" [displayKey]="'usr_name'" [valueKey]="'usr_pk'"
                                    placeholder="Select Builder" #builderTo name="builderTo" id="builderTo"
                                    formControlName="builderTo" appDropdownOpen>
                                </igx-simple-combo>
                            </div>
                        </td>
                    </tr>

                    <tr class="row">
                        <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                                <button igxButton="flat" igxRipple (click)="transferHouses()" class="me-2"
                                    style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));"
                                    [disabled]="!transferHouseForm.valid">Ok</button>
                                <button igxButton="flat" igxRipple (click)="close()"
                                    style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </igx-card-content>
        </igx-card>
    </form>
</div>