import { Component, Inject, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilteringLogic, IgxGridComponent, IgxStringFilteringOperand, IgxTextHighlightDirective, IRowSelectionEventArgs, Transaction, TransactionType } from 'igniteui-angular';
import { ActivityService } from 'src/app/core/services/activity.service';
import { LoadingService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent {
  @ViewChild('gridAddActivityDialog', { read: IgxGridComponent, static: true }) public gridAddActivityDialog!: IgxGridComponent;
  @ViewChildren(IgxTextHighlightDirective, { read: IgxTextHighlightDirective }) highlightDirectives!: QueryList<IgxTextHighlightDirective>;

  public searchActivityText: string = '';
  public caseSensitive = false;
  public exactMatch = false;

  activityList: any[] = [];
  selectedRows: any[] = [];
  selectedRow: any;

  housePk: number = 0;
  formType: string = '';
  windowWidth: any;
  //#region Variables
  public outIsFullScreen = false;
  constructor(private loader: LoadingService,
    private activityService: ActivityService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddActivityComponent>) {
    this.housePk = data.house;
    this.formType = data.type;
    this.getActivityList();
  }

  getActivityList() {
    this.loader.show();
    this.activityList = [];
    if (this.formType === 'template') {
      this.activityService.getByTemplateAddActivity(this.housePk).subscribe(res => {
        this.gridAddActivityDialog.deselectAllRows();
        this.clearActivitySearch();
        this.activityList = res.map((res: any) => {
          res.selected = false;
          return res;
        }).sort((a: any, b: any) => a.act_name.localeCompare(b.act_name));
        this.loader.hide();
      }, error => {
        this.loader.hide();
      });
    } else {
      this.activityService.getByHouseAddActivity(this.housePk).subscribe(res => {
        this.gridAddActivityDialog.deselectAllRows();
        this.clearActivitySearch();
        this.activityList = res.sort((a: any, b: any) => a.act_name.localeCompare(b.act_name));
        this.loader.hide();
      }, error => {
        this.loader.hide();
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
  addActivitySubmit() {
    // this.gridAddActivityDialog.selectedRows.forEach(activity => {
    //   const selectedRow = this.activityList.filter(x => x.act_pk === activity)[0];
    //   this.selectedRows.push(selectedRow);
    // });
    // this.dialogRef.close([...this.selectedRows]);
    const assignActivityList: any[] = [] = this.gridAddActivityDialog.transactions.getAggregatedChanges(true).filter(x => x.newValue.selected === true).map(res => {
      res.newValue.scd_pk = -1;
      res.newValue.hac_pk = -1;
      res.newValue.hac_pk = -1;
      res.newValue.scd_date = new Date(new Date().setHours(0, 0, 0, 0));
      res.newValue.scd_datecompleted = null;
      res.newValue.scd_ispinactivity = false;
      res.newValue.scd_createdby = '1';
      res.newValue.scd_createddate = new Date();
      res.newValue.scd_updatedby = null;
      res.newValue.scd_updateddate = null;
      res.newValue.scd_iscompleted = false;
      res.newValue.scd_isscheduled = false;
      res.newValue.fax_rescheduled = false;
      res.newValue.phs_order = 99;
      res.newValue.hac_notrequired = false;
      res.newValue.hac_vendorconfirm = false;
      res.newValue.hac_vendorreject = false;
      res.newValue.scd_isPOBlank = true;
      res.newValue.builderread = false;
      res.newValue.vendorread = false;
      res.newValue.vicUser = false;
      res.newValue.scd_isStageDate = false;
      res.newValue.prq_pk = null;
      res.newValue.scd_week = 0;
      res.newValue.trm_notice = 'schedule';
      res.newValue.con_pk = -1;
      res.newValue.con_name = '';
      res.newValue.act_email = false;
      res.newValue.act_fax = false;
      res.newValue.act_print = false;
      res.newValue.act_porequired = true;
      return res.newValue;
    });
    this.dialogRef.close([...assignActivityList]);
  }

  public clearActivitySearch() {
    this.searchActivityText = '';
    this.gridAddActivityDialog.clearSearch();
    this.searchActivityChange();
  }
  public searchActivityChange() {
    const dirs = this.highlightDirectives.toArray();
    this.gridAddActivityDialog.findNext(this.searchActivityText, this.caseSensitive, this.exactMatch);
    for (const dir of dirs) {
      dir.highlight(this.searchActivityText, this.caseSensitive, this.exactMatch);
    }
  }
  public searchActivityKeyDown(ev: any) {
    if (ev.key === 'Enter' || ev.key === 'ArrowDown' || ev.key === 'ArrowRight') {
      ev.preventDefault();
      this.gridAddActivityDialog.findNext(this.searchActivityText, this.caseSensitive, this.exactMatch);
    } else if (ev.key === 'ArrowUp' || ev.key === 'ArrowLeft') {
      ev.preventDefault();
      this.gridAddActivityDialog.findPrev(this.searchActivityText, this.caseSensitive, this.exactMatch);
    }
  }
  public onResize(event: any): void {
    this.windowWidth = event.target.innerWidth;
  }
  public handleRowSelection(event: IRowSelectionEventArgs) {
  }
  toggleAll(event: any, key: any) {
    let data: any[] = this.gridAddActivityDialog?.data ? this.gridAddActivityDialog?.data : [];
    data.forEach(element => {
      let refElement: any = JSON.parse(JSON.stringify(element));
      element[key] = event.checked;
      const newTransaction: Transaction = {
        id: element.act_pk,
        type: TransactionType.UPDATE,
        newValue: element
      };
      this.gridAddActivityDialog.transactions.add(newTransaction, refElement);
    });
  }
  isDialogDrag(_fullScreen: boolean) {
    this.outIsFullScreen = _fullScreen;
  }
}
