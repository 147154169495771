import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { LocalService } from './local.service';
import { AccessLevel } from 'src/app/constants';
import { PolicyService } from './policy.service';
import { delay, reject } from 'lodash';
import { RoleService } from './role.service';
import { UsersService } from './user.service';
import { AlertDialogComponent } from 'src/app/pages/dialogs/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  scopes: any = {};

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private localStorage: LocalService,
    private policyService: PolicyService,
    private dialog: MatDialog,
    private roleService: RoleService,
    private authService: MsalService,
    private userService: UsersService) {
    const scope: any = "'phone' | 'openid' | 'email'";
  }
  ngOnInit() {
    this.currentAuthenticatedUser();
  }
  async getPolicy(): Promise<any> {
    this.localStorage.removeData('policy');
    const policyData = await this.policyService.getByUser().toPromise();
    const res = policyData.map((x: any) => {
      return {
        PolicyArea: x.gat_id,
        AccessTypeId: x.pol_access
      }
    })
    this.localStorage.saveData('policy', JSON.stringify(res));
    // const res = await policyData.map(async (x: any) => {
    //   return {
    //     PolicyArea: x.gat_id,
    //     AccessTypeId: x.pol_access
    //   }
    // });
    // this.localStorage.saveData('policy', JSON.stringify(res));
    // this.policyService.getByUser().subscribe(async (res) => {
    //   res = res.map((x: any) => {
    //     return {
    //       PolicyArea: x.gat_id,
    //       AccessTypeId: x.pol_access
    //     }
    //   });
    //   this.localStorage.saveData('policy', JSON.stringify(res));
    // }, error => {
    //   console.log(error);
    // });
  }
  async getRole(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.localStorage.removeData('role');
      this.roleService.getByLoggedInUser().subscribe(async (res) => {
        res = res.map((x: any) => {
          return {
            Role: x.rol_name,
            HasPermission: x.ass_checked
          }
        });
        this.localStorage.saveData('role', JSON.stringify(res));
        resolve(res);
      }, error => {

      });
    });
  }
  async getAccessLevel(policyArea: any): Promise<any> {
    let accessLevel: number = 0;
    let policy = await this.localStorage.getData('policy');
    if (policy == null || policy === '') {
      await this.getPolicy();
    }
    policy = await this.localStorage.getData('policy');
    const permission: any = JSON.parse(policy);
    if (permission !== null && permission !== 'undefined') {
      let selectedPersmission = permission.filter((o: any) => o.PolicyArea.toLowerCase() === policyArea);
      if (selectedPersmission && selectedPersmission.length > 0) {
        if (selectedPersmission[0].AccessTypeId >= AccessLevel.ViewOnly) {
          accessLevel = selectedPersmission[0].AccessTypeId;
        }
      }
    }

    return accessLevel;
  }
  async hasPermission(policyArea: any, accessLevel: any = AccessLevel.ViewOnly): Promise<any> {
    let hasAccess: boolean = false;
    if (!policyArea) {
      return true;
    }
    let policy = await this.localStorage.getData('policy');
    if (policy == null || policy === '') {
      await this.getPolicy();
    }
    policy = await this.localStorage.getData('policy');
    const permission: any = JSON.parse(policy);
    if (permission !== null && permission !== 'undefined') {
      let selectedPersmission = permission.filter((o: any) => o.PolicyArea.toLowerCase() === policyArea);
      if (selectedPersmission && selectedPersmission.length > 0) {
        if (selectedPersmission[0].AccessTypeId >= accessLevel) {
          hasAccess = true;
        }
      }
    }

    return hasAccess;
  }
  async hasRole(role: any): Promise<any> {
    let hasAccess: boolean = false;
    let roles = await this.localStorage.getData('role');
    if (roles == null || roles === '') {
      await this.getRole();
    }
    roles = await this.localStorage.getData('role');
    const permission: any = JSON.parse(roles);
    if (permission !== null && permission !== 'undefined') {
      let selectedPersmission = permission.filter((o: any) => o.Role.toLowerCase() === role.toLowerCase() && o.HasPermission === true);
      if (selectedPersmission && selectedPersmission.length > 0) {
        hasAccess = true;
      }
    }

    return hasAccess;
  }
  async allRole(): Promise<any> {
    let roles = await this.localStorage.getData('role');
    var objRoles: any = {};
    return new Promise((resolve, reject) => {
      if (roles == null || roles === '') {
        let promiseRoles = this.getRole();
        promiseRoles.then((resultRole) => {
          if (resultRole !== null && resultRole.length > 0) {
            if (resultRole !== null && resultRole !== 'undefined' && resultRole.length > 0) {
              resultRole.forEach((result: any) => {
                objRoles[result.Role] = result.HasPermission;
              });
            }
          }
          resolve(objRoles);
        });
      } else {
        if (roles !== "") {
          const permission: any = JSON.parse(roles);
          if (permission !== null && permission !== 'undefined' && permission.length > 0) {
            permission.forEach((role: any) => {
              objRoles[role.Role] = role.HasPermission;
            });
          }
        }
        resolve(objRoles);
      }
    });
  }
  // public confirmSignUp(user: IUser): Promise<any> {
  //   return Auth.confirmSignUp({ username: user.email, : user.code});
  // }
  async currentAuthenticatedUser() {
    try {

    } catch (err) {
      console.log(err);
    }
  }
  async getUserInfo(): Promise<any> {
    let user = await this.localStorage.getData('localUser');
    return new Promise((resolve, reject) => {
      var objUser: any = {};
      if (user !== null && user !== 'undefined' && user.length > 0) {
        const u = JSON.parse(user);
        objUser = u[0];
        resolve(objUser);
      } else {
        this.userService.getCurrentUser().subscribe(res => {
          this.localStorage.saveData('localUser', JSON.stringify(res));
          resolve(res[0]);
        }, error => {
          //   const dialogRef = this.dialog.open(AlertDialogComponent, {
          //     disableClose: true,
          //     data: { message: 'You are not authorized. Please contact to administator.' }
          //   });
          //   dialogRef.afterClosed().subscribe(result => {
          //     this.signOut();
          //     this.localStorage.removeData('localUser');
          //     this.localStorage.removeData('company');
          //     this.localStorage.removeData('idToken');
          //     this.localStorage.removeData('role');
          //     this.localStorage.removeData('policy');
          //   });
        });
      }
    });
  }
  public async signIn(): Promise<any> {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        scopes: environment.scopes,
        state: window.location.hostname
      } as RedirectRequest);

    } else {
      this.authService.loginRedirect();
    }
  }

  public signOut(popup?: boolean): any {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
  async getToken(): Promise<any> {
    let startprocess: any = false;
    let accessToken: any = '';
    await this.authService.instance.acquireTokenSilent({ scopes: environment.scopes }).then(tokenResponse => {
      accessToken = tokenResponse.accessToken;
      startprocess = true;
    }).catch(() => {
      accessToken = null;
      startprocess = true;
    });
    while (!startprocess) {
    }
    return accessToken;
  }
}
