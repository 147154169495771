<igx-card-header class="dialog-header" style="z-index: 999;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle [cdkDragDisabled]="outIsFullScreen">
    Attachemnt
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="blog-form" (resize)="onResize($event)" style="height: calc(100% - 37px);overflow: auto;">
    <div style="height: calc(100%)">
        <form class="p-2 form-wrapper" [formGroup]="attachmentUploadForm">
            <igx-card>
                <igx-card-header class="igx-card-header">
                    Upload File
                </igx-card-header>
                <igx-card-content class="gradiant">
                    <table class="mt-2 w-100">
                        <tr class="row">
                            <td class="col-sm-8 col-md-8 col-lg-5 col-xl-4">
                                <label igxLabel class="igx-label tbl-label">Document Path</label>
                                <igx-input-group>
                                    <input igxInput type="file" #fileUpload (change)="onFileSelected($event)"
                                        class="igx-input" name="fileUpload" formControlName="fileUpload"
                                        accept=".jpg,.jpeg,.png,.gif,.bmp,.txt,.doc,.docx,.pdf,.xls,.xlsx,.ppt,.pptx"
                                        style="--input-size: var(--ig-size, var(--ig-size-small));--component-size: var(--ig-size, var(--ig-size-small));" />
                                </igx-input-group>
                            </td>
                            <td class="col-sm-5 col-md-4 col-lg-5 col-xl-4 manual-col-width-5 btn-browse">
                                <button igxButton="contained" igxRipple class="col-auto igx-primary-btn"
                                    (click)="fileUpload.click()">Browse
                                </button>&nbsp;&nbsp;&nbsp;
                                <button igxButton="contained" igxRipple class="igx-primary-btn"
                                    (click)="uploadFile()">Upload
                                    File
                                </button>
                            </td>
                            <b class="msg-size span-msg">Maximum upload file size: 1.5MB </b>
                            <b class="msg-size">Supported file type: JPG, JPEG, PNG, GIF, BMP, TXT, DOC,
                                DOCX, PDF, XLS, XLSX, PPT, PPTX</b>
                        </tr>
                    </table>
                </igx-card-content>
            </igx-card>
        </form>
        <div class="mat-elevation-z8 activtiy-table-wrapper" style="height: calc(100% - 160px);box-shadow:none;">
            <div class="grid__wrapper h-100">
                <div class="grid__wrapper__inner h-100"
                    [ngClass]="gridDocuments.nativeElement.clientWidth < 1024 ? 'mobile-view':'desktop-view'">
                    <igx-grid #gridDocuments [igxPreventDocumentScroll]="false" [data]="gridAttachmentData"
                        [width]="'100%'" [primaryKey]="'pot_pk'" [autoGenerate]="false" [rowSelection]="'single'"
                        [cellSelection]="'none'" [rowEditable]="false" hiddenColumnsText="Hidden"
                        [hideRowSelectors]="true">

                        <div *ngIf="screenSize < 1024">
                            <igx-column-layout>
                                <igx-column field="pot_originalname" header="File Name" [sortable]="true"
                                    [resizable]="true" [dataType]="'string'" [rowStart]="1" [colStart]="1">
                                    <ng-template igxCell let-cell="cell">
                                        <span class="inner-res-label">{{cell.column.header}} : </span>
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="pot_datecreated" header="Date Uploaded" [dataType]="'date'"
                                    [sortable]="true" [resizable]="true"
                                    [rowStart]="gridDocuments.nativeElement.clientWidth < 1024 ? 2 : 1"
                                    [colStart]="gridDocuments.nativeElement.clientWidth < 1024 ? 1 : 2">
                                    <ng-template igxCell let-cell="cell">
                                        <span class="inner-res-label">{{cell.column.header}} : </span>
                                        <span>{{cell.value | date: 'MM/dd/yyyy'}}</span>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="con_name" header="Uploaded By" [dataType]="'string'"
                                    [sortable]="true" [resizable]="true"
                                    [rowStart]="gridDocuments.nativeElement.clientWidth < 1024 ? 3 : 1"
                                    [colStart]="gridDocuments.nativeElement.clientWidth < 1024 ? 1 : 3">
                                    <ng-template igxCell let-cell="cell">
                                        <span class="inner-res-label">{{cell.column.header}} : </span>
                                        <span>{{cell.value}}</span>
                                    </ng-template>
                                </igx-column>
                                <igx-column field="" header="" [cellTemplate]="btnFileOperation" [resizable]="true"
                                    [rowStart]="1" [colStart]="4">
                                    <ng-template #btnFileOperation let-cell="cell">
                                        <div
                                            [ngClass]="gridDocuments.nativeElement.clientWidth < 1024 ? 'd-inline-block':'d-inline-flex'">
                                            <div class="button-sample me-1 mt-1">
                                                <button igxButton="flat" igxRipple
                                                    (click)="openFile(cell.row.data, 'porequestDoc')">Open
                                                </button>
                                            </div>
                                            <div class="button-sample me-1 mt-1">
                                                <button igxButton="flat" igxRipple
                                                    (click)="downloadFile(cell.row.data)">Download
                                                </button>
                                            </div>
                                            <div class="button-sample mt-1">
                                                <button igxButton="flat" igxRipple
                                                    (click)="removeFile(cell.row.data)">Remove
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </igx-column>
                            </igx-column-layout>
                        </div>

                        <div *ngIf="screenSize >= 1024">
                            <igx-column field="pot_originalname" header="File Name" [sortable]="true" [resizable]="true"
                                [dataType]="'string'">
                                <ng-template igxCell let-cell="cell">
                                    <span class="inner-res-label">{{cell.column.header}} : </span>
                                    <span>{{cell.value}}</span>
                                </ng-template>
                            </igx-column>
                            <igx-column field="pot_datecreated" header="Date Uploaded" [dataType]="'date'"
                                [sortable]="true" [resizable]="true">
                                <ng-template igxCell let-cell="cell">
                                    <span class="inner-res-label">{{cell.column.header}} : </span>
                                    <span>{{cell.value | date: 'MM/dd/yyyy'}}</span>
                                </ng-template>
                            </igx-column>
                            <igx-column field="con_name" header="Uploaded By" [dataType]="'string'" [sortable]="true"
                                [resizable]="true">
                                <ng-template igxCell let-cell="cell">
                                    <span class="inner-res-label">{{cell.column.header}} : </span>
                                    <span>{{cell.value}}</span>
                                </ng-template>
                            </igx-column>
                            <igx-column field="" header="" [cellTemplate]="btnFileOperation" [resizable]="true">
                                <ng-template #btnFileOperation let-cell="cell">
                                    <div
                                        [ngClass]="gridDocuments.nativeElement.clientWidth < 1024 ? 'd-inline-block':'d-inline-flex'">
                                        <div class="button-sample me-1 mt-1">
                                            <button igxButton="flat" igxRipple
                                                (click)="openFile(cell.row.data, 'porequestDoc')">Open
                                            </button>
                                        </div>
                                        <div class="button-sample me-1 mt-1">
                                            <button igxButton="flat" igxRipple
                                                (click)="downloadFile(cell.row.data)">Download
                                            </button>
                                        </div>
                                        <div class="button-sample mt-1">
                                            <button igxButton="flat" igxRipple
                                                (click)="removeFile(cell.row.data)">Remove
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </igx-column>
                        </div>

                    </igx-grid>
                </div>
            </div>
        </div>
    </div>
</div>