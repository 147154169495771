import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubdivisionActivityService } from './subdivision-activity.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends ApiService<any> {
  constructor(protected http: HttpClient) {
    super('report');
  }

  completedHouseJobReport(): Observable<any> {
    return this.http.get(`${this.path}/CompletedHouseJobReport`);
  }
  getWarrantyLettersNoteByHouseReport(house: any, isWarranty: any = false): Observable<any> {
    let reportName = 'WarrantyLettersNoteByHouseReport';
    if (isWarranty) {
      reportName = 'WarrantyLettersNoteWOldByHouseReport';
    }
    return this.http.get(`${this.path}/${reportName}?house=${house}`);
  }
  faxByHouseReport(id: number): Observable<any> {
    return this.http.get(`${this.path}/FaxByHouseReport?house=${id}`);
  }
  warrantyLetterActivityCostCodeMappingReport(): Observable<any> {
    return this.http.get(`${this.path}/WarrantyLetterActivityCostCodeMappingReport`);
  }
  getCurrentStageBySupervisorReport(subdivision: number, builder: number, company: number): Observable<any> {
    return this.http.get(`${this.path}/CurrentStageBySupervisorReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getCurrentStageByBuilderReport(subdivision: number, builder: number, company: number): Observable<any> {
    return this.http.get(`${this.path}/CurrentStageByBuilderReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getPerformanceRankingsBySupervisorReport(subdivision: number | null = null): Observable<any> {
    if (subdivision === null) {
      return this.http.get(`${this.path}/PerformanceRankingsBySupervisorReport?subdivision=`);
    } else {
      return this.http.get(`${this.path}/PerformanceRankingsBySupervisorReport?subdivision=${subdivision}`);
    }
  }
  getPerformanceRankingsBySupervisorReportByDate(subdivision: number, startDate: any, endDate: any): Observable<any> {
    return this.http.get(`${this.path}/PerformanceRankingsBySupervisorReport?subdivision=${subdivision}&fromDate=${startDate}&toDate=${endDate}`);
  }

  getPerformanceRankingsByBuilderReport(subdivision: number | null = null): Observable<any> {
    if (subdivision === null) {
      return this.http.get(`${this.path}/PerformanceRankingsByBuilderReport?subdivision=`);
    } else {
      return this.http.get(`${this.path}/PerformanceRankingsByBuilderReport?subdivision=${subdivision}`);
    }
  }
  getDailyActivityExtendedBySupervisorReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityExtendedBySupervisorReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getDailyActivityExtendedByBuilderReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityExtendedByBuilderReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getMilestoneBySupervisorReport() {
    return this.http.get(`${this.path}/MilestoneBySupervisorReport`);
  }
  getMilestoneByWarrantyManagerReport(): Observable<any> {
    return this.http.get(`${this.path}/MilestoneByWarrantyManagerReport`);
  }
  getMilestoneByAssistedBuilderReport(): Observable<any> {
    return this.http.get(`${this.path}/MilestoneByAssistedBuilderReport`);
  }
  getMilestoneByDesignerReport(): Observable<any> {
    return this.http.get(`${this.path}/MilestoneByDesignerReport`);
  }
  getMilestoneByBuilderReport(): Observable<any> {
    return this.http.get(`${this.path}/MilestoneByBuilderReport`);
  }
  getDailyActivityBySupervisorReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityBySupervisorReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getDailyActivityByBuilderReport(subdivision: number = 0, builder: number = 0, company: number = 0) {
    return this.http.get(`${this.path}/DailyActivityByBuilderReport?subdivision=${subdivision}&builder=${builder}&company=${company}`);
  }
  getPORequestPOImportBySupervisorReport(): Observable<any> {
    return this.http.get(`${this.path}/PORequestPOImportBySupervisorReport`);
  }
  getFaxByHouseReport(house: number): Observable<any> {
    return this.http.get(`${this.path}/FaxByHouseReport?house=${house}`)
  }
  getAutoPayrollBySupervisorReport(company: number, subdivision: number, house: number | null, checkToPay: boolean | null, checkToPaid: boolean | null, payrollCompleted: boolean | null): Observable<any> {
    if ((checkToPay == null || typeof checkToPay === 'undefined') && (checkToPaid == null || typeof checkToPaid === 'undefined') && (payrollCompleted == null || typeof payrollCompleted === 'undefined')) {
      return this.http.get(`${this.path}/AutoPayrollBySupervisorReport?company=${company}&subdivision=${subdivision}&house=${house}`)
    } else if (payrollCompleted != null) {
      return this.http.get(`${this.path}/AutoPayrollBySupervisorReport?company=${company}&subdivision=${subdivision}&checkToPay=${checkToPay}&checkToPaid=${checkToPaid}&payrollCompleted=${payrollCompleted}`);
    } else {
      return this.http.get(`${this.path}/AutoPayrollBySupervisorReport?company=${company}&subdivision=${subdivision}&checkToPay=${checkToPay}&checkToPaid=${checkToPaid}`);
    }
  }
  getAutoPayrollByBuilderReport(company: number, subdivision: number, house: number | null, checkToPay: boolean | null, checkToPaid: boolean | null, payrollCompleted: boolean | null): Observable<any> {
    if ((checkToPay == null || typeof checkToPay === 'undefined') && (checkToPaid == null || typeof checkToPaid === 'undefined') && (payrollCompleted == null || typeof payrollCompleted === 'undefined')) {
      return this.http.get(`${this.path}/AutoPayrollByBuilderReport?company=${company}&subdivision=${subdivision}&house=${house}`)
    } else if (payrollCompleted != null) {
      return this.http.get(`${this.path}/AutoPayrollByBuilderReport?company=${company}&subdivision=${subdivision}&checkToPay=${checkToPay}&checkToPaid=${checkToPaid}&payrollCompleted=${payrollCompleted}`);
    } else {
      return this.http.get(`${this.path}/AutoPayrollByBuilderReport?company=${company}&subdivision=${subdivision}&checkToPay=${checkToPay}&checkToPaid=${checkToPaid}`);
    }
  }
  getHouseActivityBlogBySupervisorReport(company: number, subdivision: number, house: number, startDate: string, endDate: string): Observable<any> {
    return this.http.get(`${this.path}/HouseActivityBlogBySupervisorReport?company=${company}&subdivison=${subdivision}&house=${house}&startDate=${startDate}&endDate=${endDate}`)
  }
  getHouseActivityBloglByBuilderReport(company: number, subdivision: number, house: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.path}/HouseActivityBloglByBuilderReport?company=${company}&subdivison=${subdivision}&house=${house}&startDate=${startDate}&endDate=${endDate}`)
  }
  getHouseHoldBySupervisorReport(company: number, subdivision: number, builder: number,): Observable<any> {
    return this.http.get(`${this.path}/HouseholdBySupervisorReport?company${company}&subdivision=${subdivision}&builder${builder}`);
  }
  getHouseHoldByBuilderReport(company: number, subdivision: number, builder: number,): Observable<any> {
    return this.http.get(`${this.path}/HouseholdByBuilderReport?company${company}&subdivision=${subdivision}&builder${builder}`);
  }
  getMaintenanceDetailBySubdivisionReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/MaintenanceDetailReport?company=${company}&subdivision=${subdivision}`);
  }
  getMaintenanceDetailByUserReport(company: number, user: number): Observable<any> {
    return this.http.get(`${this.path}/MaintenanceDetailByUserReport?company=${company}&user=${user}`);
  }
  getMaintenanceSummaryReport(company: number): Observable<any> {
    return this.http.get(`${this.path}/SubdivisionMaintenanceSummaryReport?company=${company}`);
  }
  getNoTemplateReportBySupervisorReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/NoTemplateBySupervisorReport?company=${company}&subdivision=${subdivision}`);
  }
  getNoTemplateReportByBuilderReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/NoTemplateByBuilderReport?company=${company}&subdivision=${subdivision}`);
  }
  getNotesBySupervisorReport(company: number, subdivision: number, house: number, builder: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.path}/HouseNoteBySupervisorReport?company=${company}&subdivision=${subdivision}&house=${house}&builder=${builder}&fromDate=${startDate}&toDate=${endDate} `);
  }
  getNotesByBuilderReport(company: number, subdivision: number, house: number, builder: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.path}/HouseNoteByBuilderReport?company=${company}&subdivision=${subdivision}&house=${house}&builder=${builder}&fromDate=${startDate}&toDate=${endDate} `);
  }
  getPORequestBySupervisorReport(company: number, subdivision: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.path}/PORequestBySupervisorReport?company=${company}&subdivision=${subdivision}&fromDate=${startDate}&toDate=${endDate}`);
  }
  getPORequestByBuilderReport(company: number, subdivision: number, startDate: Date, endDate: Date): Observable<any> {
    return this.http.get(`${this.path}/PORequestByBuilderReport?company=${company}&subdivision=${subdivision}&fromDate=${startDate}&toDate=${endDate}`);
  }
  getPORejectedReportBySupervisorReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/PORequestRejectBySupervisorReport?company=${company}&subdivision=${subdivision}`);
  }
  getPORejectedReportByBuilderReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/PORequestRejectByBuilderReport?company=${company}&subdivision=${subdivision}`);
  }
  getProductionBySupervisorReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/ProductionBySupervisorReport?company=${company}&subdivision=${subdivision}`);
  }
  getProductionByBuilderReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/ProductionByBuilderReport?company=${company}&subdivision=${subdivision}`);
  }
  getProductionSummaryBySupervisorReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/ProductionSummaryBySupervisorReport?company=${company}&subdivision=${subdivision}`);
  }
  getProductionSummaryByBuilderReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/ProductionSummaryByBuilderReport?company=${company}&subdivision=${subdivision}`);
  }
  getProductionStageMoveBySupervisorReport(): Observable<any> {
    return this.http.get(`${this.path}/ProductionStageMoveBySupervisorReport`);
  }
  getProductionStageMoveByBuilderReport(): Observable<any> {
    return this.http.get(`${this.path}/ProductionStageMoveByBuilderReport`);
  }
  getProjectedClosingBySupervisorReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/ProjectedClosingBySupervisorReport?company=${company}&subdivision=${subdivision}`)
  }
  getProjectedClosingByBuilderReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/ProjectedClosingByBuilderReport?company=${company}&subdivision=${subdivision}`)
  }
  getRollCallBySupervisorReport(company: number, builder: number, date: Date): Observable<any> {
    return this.http.get(`${this.path}/RollCallBySupervisorReport?company=${company}&builder=${builder}&date=${date}`);
  }
  getRollCallByBuilderReport(company: number, builder: number, date: Date): Observable<any> {
    return this.http.get(`${this.path}/RollCallByBuilderReport?company=${company}&builder=${builder}&date=${date}`);
  }
  getStageTenBySupervisorReport(company: number, subdivision: number, builder: number): Observable<any> {
    return this.http.get(`${this.path}/StageTenBySupervisorReport?company=${company}&subdivision=${subdivision}&builder=${builder}`);
  }
  getStageTenByBuilderReport(company: number, subdivision: number, builder: number): Observable<any> {
    return this.http.get(`${this.path}/StageTenByBuilderReport?company=${company}&subdivision=${subdivision}&builder=${builder}`);
  }
  getSubdivisionJobStatusBySupervisorReport(company: number, subdivision: number,): Observable<any> {
    return this.http.get(`${this.path}/SubdivisionJobStatusBySupervisorReport?company=${company}&subdivision=${subdivision}`);
  }
  getSubdivisionJobStatusByBuilderReport(company: number, subdivision: number,): Observable<any> {
    return this.http.get(`${this.path}/SubdivisionJobStatusByBuilderReport?company=${company}&subdivision=${subdivision}`);
  }
  getUrgentPOBySupervisorReport(company: number, subdivision: number, builder: number): Observable<any> {
    return this.http.get(`${this.path}/UrgentPOBySupervisorReport?company=${company}&subdivision=${subdivision}&builder=${builder}`);
  }
  getUrgentPOByBuilderReport(company: number, subdivision: number, builder: number): Observable<any> {
    return this.http.get(`${this.path}/UrgentPOByBuilderReport?company=${company}&subdivision=${subdivision}&builder=${builder}`);
  }
  getVendorPerformanceBySupervisorReport(company: number, subdivision: number, house: number, contact: number, fromDate: Date, toDate: Date): Observable<any> {
    return this.http.get(`${this.path}/VendorPerformanceBySupervisorReport?company=${company}&subdivision=${subdivision}&house=${house}&contact=${contact}&startDate=${fromDate}&endDate=${toDate}`);
  }
  getVendorPerformanceByBuilderReport(company: number, subdivision: number, house: number, contact: number, fromDate: Date, toDate: Date): Observable<any> {
    return this.http.get(`${this.path}/VendorPerformanceByBuilderReport?company=${company}&subdivision=${subdivision}&house=${house}&contact=${contact}&startDate=${fromDate}&endDate=${toDate}`);
  }
  getVendorVicScoreBySupervisorReport(company: number, subdivision: number, house: number, contact: number): Observable<any> {
    return this.http.get(`${this.path}/VicScoreBySupervisorReport?company=${company}&subdivision=${subdivision}&house=${house}&contact=${contact}`);
  }
  getVendorVicScoreByBuilderReport(company: number, subdivision: number, house: number, contact: number): Observable<any> {
    return this.http.get(`${this.path}/VicScoreByBuilderReport?company=${company}&subdivision=${subdivision}&house=${house}&contact=${contact}`);
  }
  getJobStatusBySupervisorReport(company: number, subdivision: number, builder: number): Observable<any> {
    return this.http.get(`${this.path}/JobStatusBySupervisorReport?company=${company}&subdivision=${subdivision}&builder=${builder}`);
  }
  getJobStatusByBuilderReport(company: number, subdivision: number, builder: number): Observable<any> {
    return this.http.get(`${this.path}/JobStatusByBuilderReport?company=${company}&subdivision=${subdivision}&builder=${builder}`);
  }
  getBonusBySupervisorReport(company: number, subdivision: number, builder: number, date: Date): Observable<any> {
    return this.http.get(`${this.path}/BonusReportBySupervisorReport?company=${company}&subdivision=${subdivision}&builder=${builder}&date=${date}`);
  }
  getBonusByBuilderReport(company: number, subdivision: number, builder: number, date: Date): Observable<any> {
    return this.http.get(`${this.path}/BonusReportByBuilderReport?company=${company}&subdivision=${subdivision}&builder=${builder}&date=${date}`);
  }
  getBonusFilterBySupervisorReport(company: number, subdivision: number, builder: number, fromDate: Date, toDate: Date): Observable<any> {
    return this.http.get(`${this.path}/BonusReportBySupervisorFilterReport?company=${company}&subdivision=${subdivision}&builder=${builder}&fromDate=${fromDate}&toDate=${toDate}`);
  }
  getBonusFilterByBuilderReport(company: number, subdivision: number, builder: number, fromDate: Date, toDate: Date): Observable<any> {
    return this.http.get(`${this.path}/BonusReportByBuilderFilterReport?company=${company}&subdivision=${subdivision}&builder=${builder}&fromDate=${fromDate}&toDate=${toDate}`);
  }
  getWarrantyNotesReportBySupervisorReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/WarrantyNotesBySupervisorReport?company=${company}&subdivision=${subdivision}`);
  }
  getWarrantyNotesReportByBuilderReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/WarrantyNotesByBuilderReport?company=${company}&subdivision=${subdivision}`);
  }
  getWarrantySummaryReport(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/WarrantyLettersSummaryByHouseOwnerReport?company=${company}&subdivision=${subdivision}`);
  }
  getWarrantyLettersBySupervisorReport(company: number, house: number): Observable<any> {
    return this.http.get(`${this.path}/WarrantyLettersNoteBySupervisorAllReport?company=${company}&house=${house}`)
  }
  getWarrantyLettersByBuilderReport(company: number, house: number): Observable<any> {
    return this.http.get(`${this.path}/WarrantyLettersNoteByBuilderAllReport?company=${company}&house=${house}`)
  }
  getOverviewBySupervisorReport(overview: string): Observable<any> {
    return this.http.get(`${this.path}/OverviewBySupervisorReport?report=${overview}`);
  }
  getOverviewByBuilderReport(overview: string): Observable<any> {
    return this.http.get(`${this.path}/OverviewByBuilderReport?report=${overview}`);
  }
  getOverviewCalendarReportBySupervisorReport(overview: string): Observable<any> {
    return this.http.get(`${this.path}/ScheduleDateCalendarOverviewBySupervisorReport?report=${overview}`);
  }
  getOverviewCalendarReportByBuilderReport(overview: string): Observable<any> {
    return this.http.get(`${this.path}/ScheduleDateCalendarOverviewByBuilderReport?report=${overview}`);
  }
  getNoteByHouseReport(house: number): Observable<any> {
    return this.http.get(`${this.path}/NoteByHouseReport?house=${house}`);
  }
  getPORequestReportByHouseReport(house: number, isWarranty: any = false): Observable<any> {
    return this.http.get(`${this.path}/PORequestByHouseReport?house=${house}&isWarranty=${isWarranty}`);
  }
  getHouseCalendarReport(house: number): Observable<any> {
    return this.http.get(`${this.path}/ScheduleDateCalendarReport?house=${house}`);
  }
  getGanttReportBySupervisor(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/GanttReportBySupervisorReport?company=${company}&subdivision=${subdivision}`);
  }
  getGanttReportByBuilder(company: number, subdivision: number): Observable<any> {
    return this.http.get(`${this.path}/GanttReportByBuilderReport?company=${company}&subdivision=${subdivision}`);
  }
  getSubdivisionMaintenanceBySubdivisionReport(subdivision: any): Observable<any> {
    return this.http.get(`${this.path}/SubdivisionMaintenanceBySubdivisionReport?subdivision=${subdivision}`);
  }
  getScheduleDateCalendarByContactReport(contact: any): Observable<any> {
    return this.http.get(`${this.path}/ScheduleDateCalendarByContactReport?contact=${contact}`);
  }
  getScheduleDateCalendarByTemplateReport(template: number, startDate: string): Observable<any> {
    return this.http.get(`${this.path}/ScheduleDateCalendarByTemplateReport?template=${template}&startDate=${startDate}`);
  }
  getScheduleDateCalendarByUserReport(userId: number): Observable<any> {
    return this.http.get(`${this.path}/ScheduleDateCalendarByUserReport?contact=${userId}`);
  }
}
