export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  iconType?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  policy?: string;
  isExpanded: boolean;
  role?: any[],
  badge?: {
    title?: string;
    type?: string;
  };
  children?: NavigationItem[];
}

export const NavigationItems: NavigationItem[] = [
  {
    id: 'houses',
    title: 'Houses',
    type: 'item',
    url: '/house',
    classes: 'nav-item',
    icon: 'fa fa-home',
    iconType: 'font-awesome',
    isExpanded: false,
    policy: '060'
  },
  {
    id: 'vendorTrades',
    title: 'Vendor/Trades',
    type: 'item',
    url: '/vendor-trades',
    classes: 'nav-item',
    icon: 'users-gear-solid.svg',
    iconType: 'image',
    isExpanded: false,
    policy: '050'
  },
  {
    id: 'payroll',
    title: 'Payroll',
    type: 'item',
    url: '/payroll',
    classes: 'nav-item',
    icon: 'fa fa-dollar-sign',
    iconType: 'font-awesome',
    isExpanded: false,
    policy: '112'
  },
  {
    id: 'allLists',
    title: 'All Lists',
    type: 'item',
    url: '/all-list',
    classes: 'nav-item',
    icon: 'fa fa-clipboard-list',
    iconType: 'font-awesome',
    isExpanded: false
  },
  {
    id: 'warrantyLetters',
    title: 'Warranty Letters',
    type: 'item',
    url: '/warranty-letters',
    classes: 'nav-item',
    icon: 'fa fa-calendar-check',
    iconType: 'font-awesome',
    isExpanded: false,
    policy: '129'
  },
  {
    id: 'gantt-chart',
    title: 'Gantt Chart',
    type: 'item',
    url: '/gantt-chart',
    classes: 'nav-item',
    icon: 'fa fa-chart-bar ',
    iconType: 'font-awesome',
    isExpanded: false
  },
  {
    id: 'reports',
    title: 'Reports',
    type: 'item',
    url: '/reports',
    classes: 'nav-item',
    icon: 'fa fa-file-text',
    iconType: 'font-awesome',
    isExpanded: false
  },
  {
    id: 'tasks',
    title: 'Tasks',
    type: 'collapse',
    classes: 'nav-item',
    icon: 'bars-progress-solid.svg',
    iconType: 'image',
    isExpanded: false,
    children: [
      {
        id: 'sam_option',
        title: 'SAM Option',
        type: 'item',
        url: 'tasks/sam-option',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '070',
        isExpanded: false
      },
      {
        id: 'recall',
        title: 'Recall',
        type: 'item',
        url: 'tasks/recall',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        isExpanded: false
      },
      {
        id: 'calender',
        title: 'Calender',
        type: 'item',
        url: 'tasks/calender',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        isExpanded: false
      },
      {
        id: 'blog',
        title: 'Blog',
        type: 'item',
        url: 'tasks/blog-list',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        isExpanded: false
      },
      {
        id: 'transfer-houses',
        title: 'Transefer House(s)',
        type: 'item',
        url: '',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        external: true,
        isExpanded: false
      },
      {
        id: 'sam_score_problem',
        title: 'SAM Score Problem',
        type: 'item',
        url: 'tasks/sam-score-problem',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        isExpanded: false
      },
      {
        id: 'template_modification',
        title: 'Template Modification',
        type: 'item',
        url: 'tasks/template-modification',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        isExpanded: false
      },
      {
        id: 'po_request',
        title: 'PO Request',
        type: 'item',
        url: 'tasks/po-request',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '152',
        isExpanded: false
      },
      {
        id: 'production_quarter',
        title: 'Production Quarter',
        type: 'item',
        url: 'tasks/production-quarter',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '153',
        isExpanded: false
      },
      {
        id: 'overview-schedule',
        title: 'Overview Schedule',
        type: 'item',
        url: '/overview-schedule',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        isExpanded: false
      }
    ]
  },
  {
    id: 'systemManagement',
    title: 'System Management',
    type: 'collapse',
    classes: 'nav-item',
    icon: 'fa fa-cogs',
    iconType: 'font-awesome',
    isExpanded: false,
    children: [
      {
        id: 'activities',
        title: 'Activities',
        type: 'item',
        url: '/system-management/activity',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '010',
        isExpanded: false
      },
      {
        id: 'city',
        title: 'Cities',
        type: 'item',
        url: '/system-management/city',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '114',
        isExpanded: false
      },
      {
        id: 'company',
        title: 'Companies',
        type: 'item',
        url: '/system-management/company',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '110',
        isExpanded: false
      },
      {
        id: 'company-option',
        title: 'Company Options',
        type: 'item',
        url: '/system-management/company-option',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '040',
        isExpanded: false
      },
      {
        id: 'costcode',
        title: 'Cost Codes',
        type: 'item',
        url: '/system-management/cost-code',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '111',
        isExpanded: false
      },
      {
        id: 'division',
        title: 'Divisions',
        type: 'item',
        url: '/system-management/division',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '116',
        isExpanded: false
      },
      {
        id: 'form',
        title: 'Forms',
        type: 'item',
        url: '/system-management/form',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '151',
        isExpanded: false
      },
      {
        id: 'garage-types',
        title: 'Garage Types',
        type: 'item',
        url: '/system-management/garage-types',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '142',
        isExpanded: false
      },
      {
        id: 'group',
        title: 'Groups',
        type: 'item',
        url: '/system-management/group',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '090',
        isExpanded: false
      },
      {
        id: 'holiday',
        title: 'Holidays',
        type: 'item',
        url: '/system-management/holiday',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '100',
        isExpanded: false
      },
      {
        id: 'houseGrid',
        title: 'House Grid',
        type: 'item',
        url: '/system-management/house-grid',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        isExpanded: false
      },
      {
        id: 'overview-report',
        title: 'Overview Reports',
        type: 'item',
        url: '/system-management/overview-report',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '128',
        isExpanded: false
      },
      {
        id: 'phase',
        title: 'Phases',
        type: 'item',
        url: '/system-management/phase',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '020',
        isExpanded: false
      },
      {
        id: 'plan',
        title: 'Plans',
        type: 'item',
        url: '/system-management/plan',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '113',
        isExpanded: false
      },
      {
        id: 'poi-activity',
        title: 'POI Activities',
        type: 'item',
        url: '/system-management/poi-activity',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '117',
        isExpanded: false
      },
      {
        id: 'poi-group',
        title: 'POI Groups',
        type: 'item',
        url: '/system-management/poi-group',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '121',
        isExpanded: false
      },
      {
        id: 'room',
        title: 'Rooms',
        type: 'item',
        url: '/system-management/room',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '115',
        isExpanded: false
      },
      {
        id: 'subdivision',
        title: 'Subdivisions',
        type: 'item',
        url: '/system-management/subdivision',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '030',
        isExpanded: false
      },
      {
        id: 'template',
        title: 'Templates',
        type: 'item',
        url: '/system-management/template',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '080',
        isExpanded: false
      },
      {
        id: 'user',
        title: 'Users',
        type: 'item',
        url: '/system-management/user',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '070',
        isExpanded: false
      },
      {
        id: 'variance',
        title: 'Variances',
        type: 'item',
        url: '/system-management/variance',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '126',
        isExpanded: false
      },
      {
        id: 'vendor-type',
        title: 'Vendor Types',
        type: 'item',
        url: '/system-management/vendor-type',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '127',
        isExpanded: false
      },
      {
        id: 'warranty-time-window',
        title: 'Warranty Time Window',
        type: 'item',
        url: '/system-management/warranty-time-window',
        classes: 'nav-item',
        icon: 'estimation.png',
        iconType: 'image',
        policy: '130',
        isExpanded: false
      }
    ]
  }
];
