// inline-css.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
// import * as juice from 'juice';

@Pipe({
  name: 'inlineCss'
})
export class InlineCssPipe implements PipeTransform {
  transform(html: string): string {
    if (!html) {
      return html;
    }
    try {
      // Juice will parse the HTML, find any <style> tags,
      // and then inline the CSS rules onto the elements.
      let htmlData = document.createElement('html');
      htmlData.innerHTML = html;
      let cssString = '';
      let styleList = htmlData.querySelectorAll('style');
      styleList.forEach(css => {
        cssString = cssString + css.innerText;
      });

      let classList = cssString.replaceAll('}', '}|').split('|');
      let classObj = {
        selector: '',
        rules: ''
      }
      let extractClassList: any[] = [];

      classList.forEach(res => extractClassList.push(
        {
          selector: res.split('{')[0].trim(),
          rules: res.replace(/.*{([^}]+)}/, '$1')
        }
      ));
      let lineElements: any = htmlData.querySelectorAll('.arjs-lineItem div');
      if (lineElements) {
        let css = 'height: 1px;background: #000;'
        lineElements.forEach((lineEle: any) => {

          let lineHTML = '<table cellpadding="0" cellspacing="0" border="0" style="margin:0px;width:inherit !important;"><tbody><tr><td height="0"></td></tr><tr><td height="1" bgcolor="#000000" valign="top" style="color:#000;font-size:9pt;font-family:Segoe UI"></td></tr><tr><td></td></tr></tbody></table>';
          lineEle.innerHTML = lineHTML;
        });
      }
      let urlElement: any = htmlData.querySelector('.arjs-imageItem');
      if (urlElement) {
        let url = urlElement.style.backgroundImage.replace('url("', '').replace('")', '');
        urlElement.style.textAlign = 'center';
        urlElement.style.backgroundImage = '';
        let imgTag = document.createElement('img');
        imgTag.src = url;
        imgTag.alt = '';
        imgTag.style.backgroundColor = 'transparent';
        urlElement.appendChild(imgTag);
      }
      extractClassList.forEach(cssClass => {
        try {
          cssClass.rules = cssClass.rules.trim().replaceAll('\n', '').replaceAll('\t', '');
          let rulesList: any[] = cssClass.rules.split(';');
          if (rulesList && rulesList.length > 0) {
            let cssRules = '';
            rulesList.forEach(css => {
              if (css.includes('color:') || css.includes('font-size:') || css.includes('font-weight:')) {
                cssRules = cssRules + css + ';';
              }
            });
            cssClass.rules = cssRules;

            if (cssRules !== '') {
              if (htmlData.querySelectorAll(cssClass.selector) != null
                && htmlData.querySelectorAll(cssClass.selector).length > 0) {
                if (cssClass.rules.includes('b73229')) {
                  let colorEleList = htmlData.querySelectorAll(cssClass.selector.toString());
                  if (colorEleList && colorEleList.length > 0) {
                    colorEleList.forEach(element => {
                      if (element && element.children && element.children.length > 0) {
                        let styleAttr = element.children[0].getAttribute('style');
                        if (styleAttr && styleAttr.length > 0) {
                          element.children[0].setAttribute('style', styleAttr + cssClass.rules.trim().replaceAll('\n', '').replaceAll('\t', ''));
                        }
                        else {
                          element.children[0].setAttribute('style', cssClass.rules.trim().replaceAll('\n', '').replaceAll('\t', ''));
                        }
                      }
                      let styleAttr = element.getAttribute('style');
                      if (styleAttr && styleAttr.length > 0) {
                        element.setAttribute('style', styleAttr + cssClass.rules.trim().replaceAll('\n', '').replaceAll('\t', ''));
                      }
                      else {
                        element.setAttribute('style', cssClass.rules.trim().replaceAll('\n', '').replaceAll('\t', ''));
                      }
                    });
                  }
                }

                if (cssClass.rules.includes('font-size') || cssClass.rules.includes('font-weight')) {
                  let queryEleList = htmlData.querySelectorAll(cssClass.selector.toString());
                  if (queryEleList && queryEleList.length > 0) {
                    queryEleList.forEach(element => {
                      let styleAttr = element.getAttribute('style');
                      if (styleAttr && styleAttr.length > 0) {
                        element.setAttribute('style', styleAttr + cssClass.rules.trim().replaceAll('\n', '').replaceAll('\t', ''));
                      }
                      else {
                        element.setAttribute('style', cssClass.rules.trim().replaceAll('\n', '').replaceAll('\t', ''));
                      }
                    });
                  }
                }
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
      let tables: any = htmlData.querySelectorAll('table[class*="-rpt1CustomLabelTableStyle"]');
      if (tables && tables.length > 0) {
        tables.forEach((table: any) => {
          let styleTableAttr = table.getAttribute('style');
          if (styleTableAttr && styleTableAttr.length > 0) {
            table.setAttribute('style', styleTableAttr + ';table-layout: fixed;');
          }
          else {
            table.setAttribute('style', 'table-layout: fixed;');
          }

          let tableTHEADs = table.querySelectorAll('thead');
          tableTHEADs.forEach((td: any) => {
            let styleAttr = td.getAttribute('style');
            if (styleAttr && styleAttr.length > 0) {
              td.setAttribute('style', styleAttr + ';display: table-header-group;');
            }
            else {
              td.setAttribute('style', 'display: table-header-group;');
            }
          });

          let tableTRs = table.querySelectorAll('tr');
          tableTRs.forEach((td: any) => {
            let styleAttr = td.getAttribute('style');
            if (styleAttr && styleAttr.length > 0) {
              td.setAttribute('style', styleAttr + ';page-break-inside: avoid;');
            }
            else {
              td.setAttribute('style', 'page-break-inside: avoid;');
            }
          });

          let tableTDs = table.querySelectorAll('td');
          tableTDs.forEach((td: any) => {
            let styleAttr = td.getAttribute('style');
            if (styleAttr && styleAttr.length > 0) {
              td.setAttribute('style', styleAttr + ';padding: 10px;');
            }
            else {
              td.setAttribute('style', 'padding: 10px;');
            }
          });
        });
      }
      return '<!DOCTYPE html><html>' + htmlData.innerHTML + '</html>';
      // const classRegex = /\.([a-zA-Z0-9_-]+)\s*\{/g;
      // const classes: string[] = [];
      // let match;
      // while ((match = classRegex.exec(cssString.replace(/(\\n|[\\ ])/g, ''))) !== null) {
      //   classes.push(match.input);
      // }

      //const inlinedHtml = juice(html);
      //return inlinedHtml;
    } catch (error) {
      console.error('Error inlining CSS:', error);
      return html;
    }
  }
}
