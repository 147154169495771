<igx-card-header class="dialog-header" style="z-index: 999;" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container" cdkDragHandle [cdkDragDisabled]="outIsFullScreen">
    Add Activity
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="add-activity-form" (resize)="onResize($event)" style="height: calc(100% - 36px);overflow: auto;">
    <div style="height: calc(100% - 50px);">
        <!-- <form class="form-wrapper"> -->
        <div class="mat-elevation-z8" style="height: calc(100% - 10px);box-shadow:none;">
            <div class="grid__wrapper" style="height: 100%;">
                <div class="grid__wrapper__inner" style="height: 100%;">
                    <igx-input-group type="search" class="offset">
                        <igx-prefix>
                            <igx-icon *ngIf="searchActivityText.length === 0">search</igx-icon>
                            <igx-icon *ngIf="searchActivityText.length > 0"
                                (click)="clearActivitySearch()">clear</igx-icon>
                        </igx-prefix>

                        <input #search1 id="search2" igxInput placeholder="Search" [(ngModel)]="searchActivityText"
                            [ngModelOptions]="{standalone: true}" (ngModelChange)="searchActivityChange()"
                            (keydown)="searchActivityKeyDown($event)" autocomplete="off" />

                        <igx-suffix *ngIf="searchActivityText.length > 0">
                            <div class="resultsText" *ngIf="gridAddActivityDialog.lastSearchInfo">
                                <span *ngIf="gridAddActivityDialog.lastSearchInfo.matchInfoCache.length > 0">
                                    {{ this.gridAddActivityDialog.lastSearchInfo.activeMatchIndex + 1 }} of {{
                                    gridAddActivityDialog.lastSearchInfo.matchInfoCache.length }}
                                    results
                                </span>
                                <span *ngIf="gridAddActivityDialog.lastSearchInfo.matchInfoCache.length === 0">
                                    No results
                                </span>
                            </div>
                            <div class="searchButtons">
                                <button igxIconButton="flat" igxRipple [igxRippleCentered]="true" style="background: transparent;
                                 border: none;"
                                    (click)="gridAddActivityDialog.findPrev(searchActivityText, false, false)">
                                    <igx-icon family="material" style="font-size: 30px;">navigate_before</igx-icon>
                                </button>
                                <button igxIconButton="flat" igxRipple [igxRippleCentered]="true" style="background: transparent;
                                     border: none;"
                                    (click)="gridAddActivityDialog.findNext(searchActivityText, false, false)">
                                    <igx-icon family="material" style="font-size: 30px;">navigate_next</igx-icon>
                                </button>
                            </div>
                        </igx-suffix>
                    </igx-input-group>
                    <igx-grid #gridAddActivityDialog [igxPreventDocumentScroll]="false" [data]="activityList"
                        [primaryKey]="'act_pk'" width="100%" [cellSelection]="'none'" [rowSelection]="'multiple'"
                        [hideRowSelectors]="false" hiddenColumnsText="Hidden" [emptyGridTemplate]="myTemplate"
                        style="height: calc(100% - 50px)" [autoGenerate]="false" [rowEditable]="false"
                        [batchEditing]="true" [hideRowSelectors]="true">

                        <ng-template #myTemplate>
                            <div style=" margin: 5%; text-align: center;">
                                <label style="color: black;">No data found</label>
                            </div>
                        </ng-template>

                        <igx-column field="selected" header="Select" [dataType]="'boolean'" [sortable]="true"
                            [width]="'100px'">
                            <ng-template igxCell let-cell="cell">
                                <div class="grid-check-editor">
                                    <igx-checkbox [(ngModel)]="cell.value">
                                    </igx-checkbox>
                                </div>
                            </ng-template>
                            <ng-template igxHeader let-column>
                                <igx-checkbox ssiTooltip="Select All" (change)="toggleAll($event, 'selected')"
                                    class="header-checkbox-align-middle">
                                </igx-checkbox><span>&nbsp;{{column.header}}</span>
                            </ng-template>
                        </igx-column>

                        <igx-column field="act_name" header="Activity" [dataType]="'string'" [sortable]="true">
                            <ng-template igxCell let-cell="cell">
                                <!-- <span>{{cell.value}}</span> -->
                                <div igxTextHighlight class="igx-grid__td-text" [row]="cell.row.data"
                                    [column]="cell.column.field" [containerClass]="'igx-grid__td-text'"
                                    [groupName]="'gridAddActivityDialog'" [value]="cell.value">
                                    {{cell.value}}
                                </div>
                            </ng-template>
                        </igx-column>
                    </igx-grid>
                </div>
            </div>
        </div>
        <!-- </form> -->
    </div>
    <table class="mt-2 column" style="width: 98%;">
        <tr class="row">
            <td class="col-sm-3 col-md-3 col-lg-3 col-xl-3"> </td>
            <td class="col-sm-4 col-md-4 col-lg-4 col-xl-9">
                <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                    <button igxButton="flat" igxRipple (click)="addActivitySubmit()" class="me-2"
                        style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                    <button igxButton="flat" igxRipple (click)="closeDialog()"
                        style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                </div>
            </td>
        </tr>
    </table>
</div>