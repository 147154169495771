import { Injectable } from '@angular/core';

declare var webkitSpeechRecognition: any;
declare var SpeechRecognition: any;

@Injectable({
    providedIn: 'root',
})
export class VoiceRecognitionService {

    recognition: any = null;
    isStoppedSpeechRecog = false;
    public notSupported = false;
    public tempWords: string = '';
    public text: string;

    constructor() {
        this.text = '';
        // const agent = window.navigator.userAgent.toLowerCase()
        // this.notSupported = false;
        // switch (true) {
        //     case agent.indexOf('firefox') > -1:
        //         this.notSupported = true;
        //         break;
        //     case agent.indexOf('edge') > -1:
        //         this.recognition = new webkitSpeechRecognition();
        //         break;
        //     case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        //         this.recognition = new SpeechRecognition();
        //         break;
        //     case agent.indexOf('trident') > -1:
        //         this.notSupported = true;
        //         break;
        //     case agent.indexOf('safari') > -1:
        //         this.notSupported = true;
        //         break;
        //     case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        //         this.recognition = new webkitSpeechRecognition();
        //         break;
        //     default:
        //         this.notSupported = true;
        //         break;
        // }
        try {
            this.notSupported = true;
            if ("webkitSpeechRecognition" in window) {
                this.recognition = new webkitSpeechRecognition();
            }
            if ("SpeechRecognition" in window) {
                this.recognition = new SpeechRecognition();
            }
            if (this.recognition !== null) {
                this.notSupported = false;
            }
        } catch (error) {
            this.notSupported = true;
        }
    }

    init() {
        if (!this.notSupported) {
            this.recognition.interimResults = false;
            this.recognition.continuous = false;
            this.recognition.maxAlternatives = 1000;
            this.recognition.lang = 'en-US';
            this.recognition.addEventListener('audiostart', (event: any) => {
                // console.log('audiostart : ', event);
            });
            this.recognition.addEventListener('audioend', (event: any) => {
                // console.log('audioend : ', event);
            });
            this.recognition.addEventListener('soundstart', (event: any) => {
                // console.log('soundstart : ', event);
            });
            this.recognition.addEventListener('soundend', (event: any) => {
                // console.log('soundend : ', event);
            });
            this.recognition.addEventListener('speechend', (event: any) => {
                // console.log('speechend : ', event);
            });
            this.recognition.addEventListener('speechstart', (event: any) => {
                // console.log('speechstart : ', event);
            });

            this.recognition.addEventListener('onnomatch', (event: any) => {
                const transcript = Array.from(event.results)
                    .map((result: any) => result[0])
                    .map((result: any) => result.transcript)
                    .join('');
                this.tempWords = transcript;
            });
            this.recognition.onresult = (event: any) => {
                const transcript = Array.from(event.results)
                    .map((result: any) => result[0])
                    .map((result: any) => result.transcript)
                    .join('');
                this.tempWords = transcript;
            };

            this.recognition.addEventListener('end', () => {
                setTimeout(() => {
                    if (this.isStoppedSpeechRecog) {
                        this.recognition.stop();
                        this.wordConcat();
                    } else {
                        this.recognition.stop();
                        // this.wordConcat();
                        // this.recognition.start();
                    }
                });
            });
        }
    }

    start() {
        if (!this.notSupported) {
            this.recognition.interimResults = true;
            this.recognition.lang = 'en-US';
            this.isStoppedSpeechRecog = false;
            this.recognition.start();
        }
    }

    async stop() {
        if (!this.notSupported) {
            this.recognition.stop();
            this.isStoppedSpeechRecog = true;
            await this.wordConcat();
        }
    }

    async wordConcat() {
        this.text = `${this.text}${this.tempWords}.`;
        this.tempWords = '';
    }
}
