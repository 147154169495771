import { AfterViewInit, Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { LoadingService } from 'src/app/core/services/loader.service';
import { ToasterService } from 'src/app/toaster/toaster.service';
import { PORequestAttachmentService } from 'src/app/core/services/porequest-attachment.service';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { environment } from 'src/environments/environment';
import { PORequestWOLDAttachmentsService } from 'src/app/core/services/porequest-old-attachment.service';

@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss']
})
export class AttachmentDialogComponent implements OnInit, AfterViewInit {
  @HostListener('window:resize', ['$event'])


  //#region Arrays and Objects
  attachmentUploadForm: FormGroup;
  windowWidth: any;
  public poData: any;
  public gridAttachmentData: any[] = [];
  poAttachment: any = {
    pot_pk: -1,
    pot_filedata: null,
    pot_originalname: '',
    pot_datecreated: new Date(),
    pot_houfk: 0,
    pot_porfk: 0,
    pot_client: 'SAM',
    pot_confk: 0,
    pot_createdby: 1,
    pot_createddate: new Date(),
    pot_updatedby: null,
    pot_updateddate: null
  }
  public outIsFullScreen = false;
  //#endregion

  fileSizeMessage: string = 'File size exceeds maximum limit 1.5 MB.'
  fileSizeLimit: number = environment.fileSize;

  screenSize: number = 0;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AttachmentDialogComponent>,
    private dialog: MatDialog,
    private loaderService: LoadingService,
    private toasterService: ToasterService,
    private poRequestAttachmentService: PORequestAttachmentService,
    private poRequestWOLDAttachmentsService: PORequestWOLDAttachmentsService) {
    this.attachmentUploadForm = new FormGroup({
      fileUpload: new FormControl('')
    });
    this.poData = data.pk;
  }
  public onResize(event: any): void {
    this.windowWidth = event.target.innerWidth;
  }
  ngOnInit(): void {
    this.getAttachments();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.screenSize = window.innerWidth;
    }, 50);
  }
  getAttachments() {
    //this.loaderService.show();
    if (this.poData.isWarrantyHouseOLD) {
      this.poRequestAttachmentService.getByHouse('housewold', this.poData.por_houfk).subscribe(result => {
        this.gridAttachmentData = result;
      }, error => {
        this.loaderService.hide();
      });
    } else {
      this.poRequestAttachmentService.getByHouse('house', this.poData.por_houfk).subscribe(result => {
        this.gridAttachmentData = result;
      }, error => {
        this.loaderService.hide();
      });
    }
    this.loaderService.hide();
  }
  closeDialog() {
    this.dialogRef.close({ ...this.poData });
  }
  onFileSelected(event: any) {
    this.poAttachment = {
      pot_pk: -1,
      pot_filedata: null,
      pot_originalname: '',
      pot_datecreated: new Date(),
      pot_houfk: 0,
      pot_porfk: 0,
      pot_client: 'SAM',
      pot_confk: 0,
      pot_createdby: 1,
      pot_createddate: new Date(),
      pot_updatedby: null,
      pot_updateddate: null
    }
    const file: File = event.target.files[0];
    const ext = file.name.split('.').pop();
    if (['txt', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(ext!.toLowerCase())) {
      if (file) {
        setTimeout(() => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e: any) => {
            const bytes = e.target.result.split('base64,')[1];
            if ((bytes.length / 1000 / 1000) <= this.fileSizeLimit) {
              this.poAttachment = {
                pot_pk: -1,
                pot_filedata: bytes,
                pot_originalname: file.name,
                pot_datecreated: new Date(),
                pot_houfk: this.poData.por_houfk,
                pot_porfk: this.poData.por_pk,
                pot_client: 'SAM',
                pot_confk: '1',
                pot_createdby: '1',
                pot_createddate: new Date(),
                pot_updatedby: null,
                pot_updateddate: null
              }
            } else {
              this.attachmentUploadForm.reset();
              this.toasterService.show('warning', '', this.fileSizeMessage);
            }
          };
        }, 500);
      }
    } else {
      this.dialog.open(AlertDialogComponent, {
        disableClose: true,
        data: { message: 'File extension not valid. Please select another file' }
      });
    }
  }
  uploadFile() {
    if (this.attachmentUploadForm.valid) {
      if (this.attachmentUploadForm.value.fileUpload != "") {
        this.loaderService.show();
        if (this.poData.isWarrantyHouseOLD) {
          this.poRequestWOLDAttachmentsService.create(this.poAttachment).subscribe(result => {
            this.loaderService.hide(0);
            this.getAttachments();
            this.poAttachment = [];
            this.attachmentUploadForm.reset();
            this.toasterService.show('success', '', 'File uploaded successfully');
          }, error => {
            this.loaderService.hide(0);
            this.toasterService.show('error', '', 'File not uploaded, something is wrong');
          });
        } else {
          this.poRequestAttachmentService.create(this.poAttachment).subscribe(result => {
            this.loaderService.hide(0);
            this.getAttachments();
            this.poAttachment = [];
            this.attachmentUploadForm.reset();
            this.toasterService.show('success', '', 'File uploaded successfully');
          }, error => {
            this.loaderService.hide(0);
            this.toasterService.show('error', '', 'File not uploaded, something is wrong');
          });
        }
      }
      else {
        this.toasterService.show('warning', '', 'Please select file.');
      }
    }
  }
  downloadFile(dataRow: any) {
    const src = `data:image/jpg;base64,${dataRow.pot_filedata}`;
    const link = document.createElement("a");
    link.href = src;
    link.download = dataRow.pot_originalname;
    link.click();
  }
  removeFile(dataRow: any) {
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      data: { message: 'Are you sure you wish to delete file?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.loaderService.show();
        this.poRequestAttachmentService.delete(dataRow.pot_pk).subscribe(result => {
          this.loaderService.hide(0);
          this.getAttachments();
          this.toasterService.show('success', '', 'File deleted successfully');
        }, error => {
          this.loaderService.hide(0);
          this.toasterService.show('error', '', 'File not deleted, something is wrong');
        });
      }
    });
  }
  openFile(data: any, type: string) {
    const imageExt = ["jpg", "jpeg", "gif", "png", "bmp"];
    const pdfExt = ['pdf'];
    const textExt = ['txt'];
    const pptExt = ["ppt", "pptx"];
    const xlsExt = ["xls", "xlsx"];
    const docExt = ["doc", "docx"];
    let ext = '';
    let _filedata = '';
    let _fileName = '';
    if (type === 'warranty') {
      ext = data.wat_originalname.split('.').pop();
      _filedata = data.wat_filedata;
      _fileName = data.wat_originalname;
    } else if (type === 'attachment') {
      ext = data.att_originalname.split('.').pop();
      _filedata = data.att_filedata;
      _fileName = data.att_originalname;
    }
    else if (type === 'porequestDoc') {
      ext = data.pot_originalname.split('.').pop();
      _filedata = data.pot_filedata;
      _fileName = data.pot_originalname;
    }

    if (data && data != null && imageExt.includes(ext.toLowerCase())) {
      var image = new Image();
      image.style.cssText = "margin: 0;  position: absolute;  top: 50%;  left: 50%;  transform: translate(-50%, -50%);";
      image.src = "data:image/jpg;base64," + _filedata;
      var w = window.open("");
      w!.document.write(image.outerHTML);
    } else if (data && data != null && pdfExt.includes(ext.toLowerCase())) {
      const arrBuffer = this.base64ToArrayBuffer(_filedata);
      const blob = new Blob([arrBuffer], { type: 'application/pdf' });
      const linkSource = window.URL.createObjectURL(blob);
      window.open(linkSource);
    } else if (data && data != null && textExt.includes(ext.toLowerCase())) {
      const arrBuffer = this.base64ToArrayBuffer(_filedata);
      const blob = new Blob([arrBuffer], { type: 'text/plain' });
      const linkSource = window.URL.createObjectURL(blob);
      window.open(linkSource, '_blank');
    } else {
      const arrBuffer = this.base64ToArrayBuffer(_filedata);
      let blob = new Blob();
      //const blob = new Blob([arrBuffer], { type: 'application/pdf' });
      if (pptExt.includes(ext.toLowerCase())) {
        blob = new Blob([arrBuffer], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
      }
      else if (xlsExt.includes(ext.toLowerCase())) {
        blob = new Blob([arrBuffer], { type: 'application/vnd.ms-excel' });
      }
      else if (docExt.includes(ext.toLowerCase())) {
        blob = new Blob([arrBuffer], { type: 'application/msword' });
      }
      else {
        blob = new Blob([arrBuffer], { type: 'application/pdf' });
      }
      const linkSource = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = _fileName;
      downloadLink.click();
      URL.revokeObjectURL(linkSource);
      this.toasterService.show('success', '', 'File downloaded sucessfully.');
    }
  }

  base64ToArrayBuffer(data: any) {
    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  isDialogDrag(_fullScreen: boolean) {
    this.outIsFullScreen = _fullScreen;
  }
}
