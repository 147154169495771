<igx-card-header class="dialog-header" style="z-index: 999;" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container" cdkDragHandle [cdkDragDisabled]="outIsFullScreen">
    {{ cardHeader | titlecase }}
</igx-card-header>
<button igxButton="flat" class="btn-screen-mode col-auto igx-primary-btn close-button" appMatDialogFullScreen="false"
    style="--component-size: var(--ig-size, var(--ig-size-medium));" (outFullScreen)="isDialogDrag($event)">
    <div *ngIf="!outIsFullScreen">
        <mat-icon class="close-icon">fullscreen</mat-icon>
    </div>
    <div *ngIf="outIsFullScreen">
        <mat-icon class="close-icon">fullscreen_exit</mat-icon>
    </div>
</button>
<button igxButton="flat" class="btn-close-icon col-auto igx-primary-btn close-button" (click)="closeDialog()"
    style="--component-size: var(--ig-size, var(--ig-size-medium));">
    <igx-icon class="close-icon" color="warn">close</igx-icon>
</button>
<div id="house-dialog-form" (resize)="onResize($event)" style="height: calc(100% - 31px);overflow: auto;">
    <div style="height: calc(100%)">
        <form class="form-wrapper" id="dialog-house-on-hold" style="overflow: auto;">
            <igx-card>
                <igx-card-content>
                    <table class="mt-2 column" style="width: 100%;">
                        <tr class="row" *ngIf="type==='householdnote'">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label igxLabel class="igx-label asterisklabel">Note :</label>
                                <div mat-dialog-content>
                                    <igx-input-group>
                                        <textarea igxInput InputBoxTrim name="txtNote" cols="30" rows="5"
                                            [(ngModel)]="notesObj.hhn_note"
                                            [ngModelOptions]="{standalone: true}"></textarea>
                                    </igx-input-group>
                                </div>
                                <!-- <mat-error *ngIf="notesObj.hhn_note.length < 1">
                                    Required
                                </mat-error> -->
                                <igx-hint class="alert" *ngIf="notesObj.hhn_note.length < 1">Required</igx-hint>
                            </td>
                        </tr>
                        <tr class="row" *ngIf="type==='housenote'">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <label igxLabel class="igx-label asterisklabel">Note :</label>
                                <div mat-dialog-content>
                                    <igx-input-group>
                                        <textarea igxInput InputBoxTrim name="txtNote" cols="30" rows="5"
                                            [(ngModel)]="notesObj.hon_note"
                                            [ngModelOptions]="{standalone: true}"></textarea>
                                    </igx-input-group>
                                </div>
                                <!-- <mat-error *ngIf="notesObj.hon_note.length < 1">
                                    Required
                                </mat-error> -->
                                <igx-hint class="alert" *ngIf="notesObj.hon_note.length < 1">Required</igx-hint>
                            </td>
                        </tr>
                        <tr class="row" *ngIf="type==='creditdays'">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div mat-dialog-content>
                                    <label igxLabel class="igx-label asterisklabel">Requested Days :</label>
                                    <igx-input-group>
                                        <input igxInput InputBoxTrim class="igx-input"
                                            [(ngModel)]="notesObj.crd_requesteddays"
                                            [ngModelOptions]="{standalone: true}" name="requested" type="number"
                                            required />
                                    </igx-input-group>
                                </div>
                                <igx-hint class="alert"
                                    *ngIf="notesObj.crd_requesteddays === null || notesObj.crd_requesteddays === 0 || notesObj.crd_requesteddays === ''">Required</igx-hint>
                                <igx-hint class="alert" *ngIf="notesObj.crd_requesteddays < 0">Requested Days can't be
                                    less then zero. </igx-hint>
                            </td>
                        </tr>
                        <tr class="row" *ngIf="type==='creditdays'">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div mat-dialog-content>
                                    <label igxLabel class="igx-label asterisklabel">Approved Days :</label>
                                    <igx-input-group>
                                        <input igxInput InputBoxTrim class="igx-input"
                                            [(ngModel)]="notesObj.crd_approveddays"
                                            [ngModelOptions]="{standalone: true}" name="approved" type="number"
                                            required />
                                    </igx-input-group>
                                </div>
                                <igx-hint class="alert"
                                    *ngIf="notesObj.crd_approveddays === null || notesObj.crd_approveddays === 0 || notesObj.crd_approveddays === ''">Required</igx-hint>
                                <igx-hint class="alert" *ngIf="notesObj.crd_approveddays < 0">Approved
                                    Days can't be less then zero.</igx-hint>
                                <igx-hint class="alert"
                                    *ngIf="notesObj.crd_approveddays > 0 && notesObj.crd_requesteddays > 0 && notesObj.crd_requesteddays < notesObj.crd_approveddays">Approved
                                    Days can't be greater then requested days.</igx-hint>
                            </td>
                        </tr>

                        <!-- <tr class="row">
                            <td class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div mat-dialog-actions class="d-flex justify-content-end button-sample">
                                    <button igxButton="flat" igxRipple (click)="saveData()" class="me-2"
                                        style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
                                    <button igxButton="flat" igxRipple (click)="closeDialog()"
                                        style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>
                                </div>
                            </td>
                        </tr> -->
                    </table>
                </igx-card-content>
            </igx-card>
        </form>
        <div igxDialogActions>
            <button igxButton="flat" igxRipple (click)="saveData()" class="me-2"
                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1);--component-size: var(--ig-size, var(--ig-size-large));">Ok</button>
            <button igxButton="flat" igxRipple (click)="closeDialog()"
                style="--is-medium: clamp((var(--component-size, 1) + 2) - var(--ig-size-small, 1), 1); ">Cancel</button>

        </div>
    </div>
</div>