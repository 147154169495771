// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageInfo from '../../package.json';

export const environment = {
  isLocal: false,
  production: false,
  appVersion: packageInfo.version,
  fileSize: 2,
  clientId: '3ec6a1cd-d079-4123-bd33-d61fe62314e5',
  authority: 'https://devatsciem.ciamlogin.com/',
  scopes: ['api://1a85dc33-eeec-4410-a14b-eb3b79ad46d2/default'],
  redirectUri: 'https://dev-app.automatedtrackers.com/redirect',
  apiUri: 'https://dev-api.automatedtrackers.com/api/'
};
